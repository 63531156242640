import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useRequest from 'hooks/useRequest';
import { getCityCountry } from 'api';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

const noop = () => {};

export default function CityCountry({
  setFieldValue, errors, name, label, setDefaultValue = noop, isSendOrder,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [InputValue, setInputValue] = React.useState({ name: 'Select' });
  const [loading] = React.useState(open && options.length === 0);
  const { doRequest, errors: RemoteError } = useRequest({ url: '' });

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (InputValue) setFieldValue(name, InputValue.name);
    if (InputValue && InputValue.name === 'Select') {
      setInputValue({ name: 'London, United Kingdom' });
      setDefaultValue(setInputValue);
    }
  }, [InputValue]);

  const onInputChange = _.debounce((event, value) => {
    if (value.trim()) {
      (async () => {
        let ccformat = {};
        if (name === 'from' && !isSendOrder && (value === 'any' || value === 'Any')) {
          ccformat = [{ name: 'Any City, Country' }];
        } else {
          const { data: ccOptions } = await doRequest(getCityCountry(value));

          ccformat = ccOptions.map((cc) => ({ name: cc.cityCountry }));
        }

        if (ccformat !== undefined) {
          setOptions(ccformat);
        }
      })();
    }
  }, 500);

  return (
    <>
      {RemoteError}
      <Autocomplete
        id={name}
        style={{ width: '100%' }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        value={InputValue}
        onClose={() => {
          setOpen(false);
        }}
        defaultValue="City, Country"
        noOptionsText=""
        getOptionSelected={(option, val) => option.name === val.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        onInputChange={onInputChange}
        onChange={(event, newValue) => {
          if (newValue) {
            setInputValue(newValue);
            setFieldValue(name, newValue.name);
          }
        }}
        className="text-transform-capatilize"
        error={Boolean(errors.from)}
        helperText={errors.from}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label.replace(/\b(\w)/g, (s) => s.toUpperCase())}
            autoComplete={false}
            variant="outlined"
            placeholder="City, Country"
            InputProps={{
              ...params.InputProps,
              startAdornment: (<></>),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}
