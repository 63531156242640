import React from 'react';
import { TealLayout } from 'components/layouts';
import ResetPasswordForm from 'components/forms/resetPassword';

const index = () => (
  <TealLayout title="Reset Password">
    <div className="d-flex justify-content-around flex-column w-md-50 mt-5 h-75">

      <ResetPasswordForm />
    </div>

  </TealLayout>
);

export default index;
