import React from 'react';

import { TealLayout } from 'components/layouts';
import TermCondition from './termCondition';

const index = () => (
  <TealLayout title="Term & Condition">
    <div className="d-flex justify-content-around flex-column w-md-50 mt-5 h-75">

      <TermCondition />

    </div>

  </TealLayout>
);

export default index;
