/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import {
  TextField, InputAdornment, Grid, Checkbox,
  FormControlLabel, /* Radio, RadioGroup, */
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAlert from 'hooks/useAlert';
import moment from 'moment';
import useTrip from 'hooks/trip/useTrip';
import useRedirect from 'hooks/useRedirect';
import CityCountry from 'components/cityCountry';
import useQueryString from 'hooks/useQueryString';
import { SecondaryButton } from 'components/buttons/buttons';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'views/trip/index.scss';

function AddTrip() {
  // const [selectedpaymentType, setaymentType] = useState('basic');

  // const { successAlert, displayAlert } = useAlert();
  /* const handleServiceChange = (event) => {
    setpaymentType(event.target.value);
  }; */
  const validationSchema = yup.object({
    availableSpace: yup
      .number('Enter available space')
      .max(500, 'too much!!')
      .min(1, 'Invalid Value')
      .required('Please enter available space in KG.'),
    description: yup
      .string()
      .max(300, 'Too Long !! only 300 characters allowed.'),
  });

  const [addTrip] = useTrip();
  const [redirectTo] = useRedirect();
  const [DisplayError, setDisplayError] = useState('');
  const [errorAlert,, successAlert, , displayAlert] = useAlert();

  const {
    handleSubmit, values, handleChange, touched, errors, isSubmitting, setFieldValue,
  } = useFormik({
    initialValues: {
      name: '',
      departure: '',
      availableSpace: '',
      arrival: '',
      travelDate: moment().format(),
      description: '',
      paymentDetail: {
        cardNumber: '',
        bank: '',
        cvv: '',
        iban: '',
        cardHolderName: '',
        expireDate: '',
      },
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (valz, {
      validate, resetForm, setFieldError, setSubmitting,
    }) => {
      if (validate) validate(valz);
      const {
        departure, availableSpace, arrival, description, travelDate,
        cardNumber, bank, cvv, iban, cardHolderName, expireDate, paymentDetail,
      } = valz;
      // eslint-disable-next-line no-param-reassign
      paymentDetail.expireDate = paymentDetail.expireDate && moment(paymentDetail.expireDate).format('D/MM/yyyy');
      addTrip({
        departure,
        availableSpace,
        travelDate: moment(travelDate).format('D/MM/yyyy'),
        arrival,
        description,
        paymentType: 'basic',
        paymentDetail: {
          cardNumber, bank, cvv, iban, cardHolderName, expireDate,
        },

      }).then(({ success, data, message }) => {
        if (success === true) {
          successAlert(message);
          setTimeout(() => {
            resetForm();
            redirectTo('tripDetail', { id: data._id });
          }, 2000);
        }
      })
        .catch(({ message }) => {
          setSubmitting(false);
          setFieldError(message);
          setDisplayError(message);
          setTimeout(() => {
            setDisplayError('');
          }, 2000);
        });
    },
  });
  const [params, setParams] = useQueryString();
  const handleTermsConditionModal = () => {
    setParams({ termCondition: true });
    // setTermsAndConditions(true);
  };

  const termsAndConditions = params.termsAccepted;

  return (
  // eslint-disable-next-line react/jsx-no-duplicate-props
    <form className="pt-2 trip_form" noValidate autoComplete="off" onSubmit={handleSubmit}>
      {DisplayError && errorAlert(DisplayError) }
      {displayAlert}
      <Grid container justify="center" spacing={1} direction="row">
        <Grid item xs={12} md={11}>
          <Grid container justify="space-between" spacing={2} direction="row">
            <Grid item xs={12} md={6}>
              <Grid container justify="space-between" direction="row">
                <Grid item xs={12} md={6}>
                  <CityCountry setFieldValue={setFieldValue} errors={errors} name="departure" label="Where Are You Travelling From?" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CityCountry setFieldValue={setFieldValue} errors={errors} name="arrival" label="Where Are You Travelling To?" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="When Are You Travelling?"
                      id="travelDate"
                      value={values.travelDate}
                      onChange={(value) => {
                        setFieldValue('travelDate', value);
                      }}
                      placeholder="d/mm/yy"
                      inputVariant="outlined"
                      animateYearScrolling
                      format="D/MMM/yyyy"
                      disablePast
                      minDate={values.travelDate}
                      maxDate={moment().add(45, 'days')}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="availableSpace"
                    label="How Much Space Do You Have?"
                    type="number"
                    placeholder="KG"
                    className="mt-3"
                    autoComplete="availableSpace"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                      endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                    }}
                    variant="outlined"
                    value={values.availableSpace}
                    onChange={handleChange}
                    error={touched.availableSpace && Boolean(errors.availableSpace)}
                    helperText={touched.availableSpace && errors.availableSpace}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="description"
                label="Would You Like To Tell Us More?"
                placeholder="Description"
                multiline
                rows={7}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                  maxLength: 300,
                }}
          // className="mt-3 mr-3"
                defaultValue="="
                variant="outlined"
                value={values.description}
                onChange={handleChange}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={11} className="border-bottom" />
        {/* <Grid item xs={12} md={11}>
          <Typography variant="h5" className="text-uppercase pt-3 mt-3" display="block" color="primary" gutterBottom>
            Which service do you want to use?
          </Typography>

        </Grid>
        <Grid item xs={12} md={11}>
          <RadioGroup row aria-label="position" id="paymentType" name="paymentType" defaultValue="top">
            <FormControlLabel
              value="premium"
              control={<Radio color="secondary" />}
              label="Premium"
              labelPlacement="end"
              onChange={handleServiceChange}
            />
            <FormControlLabel
              value="basic"
              control={<Radio color="secondary" />}
              label="Basic"
              checked={selectedpaymentType === 'basic'}
              labelPlacement="end"
              onChange={handleServiceChange}
            />
          </RadioGroup>
        </Grid>
        {selectedpaymentType === 'premium' && (
          <Grid item xs={12} md={11}>
            <Grid item xs={12} md={11}>
              <Typography variant="h5" className="text-capitalize" display="block" color="primary" gutterBottom>
                Payment Detail
              </Typography>
            </Grid>
            <Grid container justify="space-between" spacing={2}>
              <Grid item xs={12} sm={3}>
                <Grid container justify="space-between" direction="column">
                  <TextField
                    id="cardNumber"
                    label="Card Number"
                    type="text"
                    className="mt-3"
                    autoComplete="cardNumber"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                      endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                    }}
                    variant="outlined"
                    value={values.cardNumber}
                    onChange={handleChange}
                    error={touched.cardNumber && Boolean(errors.cardNumber)}
                    helperText={touched.cardNumber && errors.cardNumber}
                  />
                  <TextField
                    id="bank"
                    label="bank"
                    type="text"
                    className="mt-3"
                    autoComplete="bank"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                      endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                    }}
                    variant="outlined"
                    value={values.bank}
                    onChange={handleChange}
                    error={touched.bank && Boolean(errors.bank)}
                    helperText={touched.bank && errors.bank}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid container justify="space-between" direction="column">
                  <TextField
                    id="cardHolderName"
                    label="card Holder Name"
                    type="text"
                    className="mt-3"
                    autoComplete="cardHolderName"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                      endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                    }}
                    variant="outlined"
                    value={values.cardHolderName}
                    onChange={handleChange}
                    error={touched.cardHolderName && Boolean(errors.cardHolderName)}
                    helperText={touched.cardHolderName && errors.cardHolderName}
                  />
                  <TextField
                    id="iban"
                    label="IBAN"
                    type="text"
                    className="mt-3"
                    autoComplete="iban"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                      endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                    }}
                    variant="outlined"
                    value={values.iban}
                    onChange={handleChange}
                    error={touched.iban && Boolean(errors.iban)}
                    helperText={touched.iban && errors.iban}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid container justify="space-between" direction="column">
                  <TextField
                    id="cvv"
                    label="CVV"
                    type="text"
                    className="mt-3"
                    autoComplete="cvv"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                      endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                    }}
                    variant="outlined"
                    value={values.cvv}
                    onChange={handleChange}
                    error={touched.cvv && Boolean(errors.cvv)}
                    helperText={touched.cvv && errors.cvv}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid container justify="space-between" direction="column">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="expireDate"
                      id="expireDate"
                      value={values.expireDate}
                      onChange={(value) => setFieldValue('expireDate', value)}
                      placeholder="dd/mm/yy"
                      inputVariant="outlined"
                      animateYearScrolling
                      format="D/MMM/yyyy"
                      disablePast
                      // openTo="[year, month]"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        */ }
        <Grid item xs={11}>
          <FormControlLabel
            control={(
              <Checkbox
                value="uncontrolled"
                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                onClick={handleTermsConditionModal}
                checked={termsAndConditions || false}
              />
            )}
            label=" I agree with the Terms & Conditions and the Privacy Policy of PACKR"
          />
        </Grid>

        <Grid item xs={0} md={5} />
        <Grid item xs={7} md={2}>
          <SecondaryButton
            className="mt-5 py-2"
            endIcon={<DoubleArrowOutlinedIcon />}
            type="submit"
            disabled={isSubmitting || !termsAndConditions}
            fullWidth
          >
            Submit
          </SecondaryButton>
        </Grid>
        <Grid item xs={1} md={5} />

      </Grid>
    </form>
  );
}

export default AddTrip;
