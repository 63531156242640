import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  node: {
    width: '7.7rem',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '.75rem',
    [theme.breakpoints.down('xs')]: {
      width: '5.5rem',
    },

  },
  smalltxt: {
    fontSize: '12px',
  },
}));

export default function InfoNode({
  type, city, date, truncate = true,
}) {
  const classes = useStyles();
  return (
    <div className={classes.node}>
      <Typography variant="body1" className={classes.smalltxt}>
        {type}
      </Typography>
      <Typography variant="h5" color="primary" title={city}>
        {truncate ? _.truncate(city, { length: 25 }) : city }
      </Typography>
      <Typography variant="caption" noWrap className={classes.smalltxt}>
        {date}
      </Typography>
    </div>
  );
}
