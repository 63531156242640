/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';
import { auth as authSelector } from 'state/selectors/auth';
import { useSelector } from 'react-redux';
import Camera from 'assets/camera40.png';
import ButtonBasses from './buttonBasses';

function display(message) {
  // setOpen(true);
  // setMsg(message);
  console.log(message);
}
const ImageUpload = ({
  uploadEndPoint, setFieldValue, name, responseField, disabled,
}) => {
  const [progress, setProgress] = React.useState('none');
  const [frontImg, setFrontImg] = React.useState(Camera);
  const auth = useSelector(authSelector);

  const onButtonClick = (ref) => {
    // `current` points to the mounted text input element
    ref.current.click();
  };

  // eslint-disable-next-line consistent-return
  const handleImageUpload = (e) => {
    // console.log(e.target);
    try {
      if (e.target.files && true) {
        const files = Array.from(e.target.files);
        const formData = new FormData();
        const types = ['image/png', 'image/jpeg', 'image/gif'];

        if (files.length > 1) {
          const msg = 'Only 1 images can be uploaded at a time';
          return display(msg);
        }
        const file = files[0];
        if (types.every((type) => file.type !== type)) {
          console.log(`'${file.type}' is not a supported format`);
        }
        setFrontImg(URL.createObjectURL(file));
        // if (file.size > 150000) {
        //   errs.push(`'${file.name}' is too large, please pick a smaller file`)
        // }

        //   if (values[e.target.id] !== '')
        //  formData.append('delete_previous', values[e.target.id]);
        //   else formData.append('delete_revious', false);

        formData.append('image', file);

        //   if (errs.length) {
        //     return errs.forEach((err) => display(err));
        //   }
        // const target = e.target.id;

        setProgress('block');

        const token = `Bearer ${auth.user.data.access_token}`;
        axios.post(uploadEndPoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        }).then((res) => {
          setFieldValue(name, res.data[responseField]);
          setProgress('none');
        })
          .catch((err) => console.log(err));

        return true;
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const front = React.useRef(null);
  return (
    <span className="d-flex flex-row m-1" style={{ width: '45px' }}>
      <input
        accept="image/*"
        className="d-none"
        id="front_view"
        multiple={false}
        type="file"
        ref={front}
        onChange={handleImageUpload}
        disabled={disabled}
      />
      <label htmlFor="fornt_view">

        <ButtonBasses
          title="mount"
          onClick={() => onButtonClick(front)}
          img={frontImg}
          disabled={disabled}
        />
        <LinearProgress
          color="secondary"
          variant="query"
          id="front_view_progress"
          style={{
            display: `${progress}`,
            width: '40px',
          }}
        />
      </label>
    </span>
  );
};

export default ImageUpload;
