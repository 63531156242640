/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import {
  TextField, InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAlert from 'hooks/useAlert';
import useAuth from 'hooks/useAuth';
import { LockOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton } from 'components/buttons/buttons';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function ChangePassword(props) {
  const { closeChangePassword } = props;
  const classes = useStyles();
  const [DisplayError, setDisplayError] = useState('');
  const [DisplaySuccess, setDisplaySuccess] = useState('');
  const [errorAlert,, , , , successpAlert] = useAlert();

  const validationSchema = yup.object({
    password: yup
      .string('Enter your password')
      .min(5, 'Atleast 5 characters are required')
      .max(10, 'Maximum 10 characters long')
      .required('Password is required'),
    confirmPassword: yup
      .string('Re-Enter Password')
      .required('Password is required')
      .test('isConfirm', 'password are not same', (cpass) => cpass === values.password),
    currentPassword: yup
      .string('Enter your password')
      .min(5, 'Atleast 5 characters are required')
      .max(10, 'Maximum 10 characters long')
      .required('Current Password is required'),

  });
  const { changePass } = useAuth();

  const {
    handleSubmit, values, handleChange, touched, errors, isSubmitting,
  } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      currentPassword: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (valz, actions) => {
      const {
        validate, resetForm, setSubmitting,
      } = actions;

      if (validate) validate(valz);

      const {
        password, confirmPassword, currentPassword,
      } = valz;

      changePass({
        currentPassword,
        newPassword: password,
        confirmPassword,

      }).then(({ success, message }) => {
        if (success === true) {
          resetForm();
          setDisplaySuccess(message);
          setTimeout(() => {
            closeChangePassword();
            setDisplaySuccess('');
          }, 2000);
        }
      })
        .catch(({ message }) => {
          setSubmitting(false);
          // setFieldError(message.includes('Password') ? 'password' : 'mobile', message);
          setDisplayError(message);
          setTimeout(() => {
            setDisplayError('');
          }, 2000);
        });
    },
  });

  return (
    // eslint-disable-next-line react/jsx-no-duplicate-props
    <form className="pt-3" className={classes.root} noValidate autoComplete="off" className="align-self-center d-flex flex-column justify-content-around" onSubmit={handleSubmit}>
      {DisplayError && errorAlert(DisplayError) }
      {DisplaySuccess && successpAlert(DisplaySuccess) }
      <TextField
        id="currentPassword"
        label="Current Password"
        type="password"
        className="mt-3"
        autoComplete="currentPassword"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.currentPassword}
        onChange={handleChange}
        error={touched.currentPassword && Boolean(errors.currentPassword)}
        helperText={touched.currentPassword && errors.currentPassword}
      />
      <TextField
        id="password"
        label="Password"
        type="password"
        className="mt-3"
        autoComplete="password"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.password}
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
      />

      <TextField
        id="confirmPassword"
        label="Confirm Password"
        type="password"
        className="mt-3"
        autoComplete="confirmPassword"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.confirmPassword}
        onChange={handleChange}
        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
        helperText={touched.confirmPassword && errors.confirmPassword}
      />
      <SecondaryButton
        className="mt-5 py-3"
        endIcon={<DoubleArrowOutlinedIcon />}
        type="submit"
        disabled={isSubmitting}
      >
        Submit
      </SecondaryButton>

    </form>
  );
}

export default ChangePassword;
