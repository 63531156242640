import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer, { initialState as authInit } from 'state/slices/auth';
import orderReducer, { initialState as orderInit } from 'state/slices/order';
import tripReducer, { initialState as tripInit } from 'state/slices/trip';
import ordersReducer, { initialState as ordersInit } from 'state/slices/orders';
import tripsReducer, { initialState as tripsInit } from 'state/slices/trips';
import notificationReducer, { initialState as notificationInit } from 'state/slices/notification';
import orderChatsReducer, { initialState as orderChatsInit } from 'state/slices/orderChats';
import tripChatsReducer, { initialState as tripChatsInit } from 'state/slices/tripChats';
import orderChatStatusReducer, { initialState as orderChatStatusInit } from 'state/slices/orderChatStatus';
import tripChatStatusReducer, { initialState as tripChatStatusInit } from 'state/slices/tripChatStatus';
import acceptedOrderReducer, { initialState as acceptedOrderInit } from 'state/slices/acceptedOrder';
import requestedOrderReducer, { initialState as requestedOrderInit } from 'state/slices/requestedOrder';
import reviewsReducer, { initialState as reviewsInit } from 'state/slices/reviews';
import orderHistoryReducer, { initialState as orderHistoryInit } from 'state/slices/orderHistory';
import tripHistoryReducer, { initialState as tripHistoryInit } from 'state/slices/tripHistory';
import reasonReducer, { initialState as reasonInit } from 'state/slices/reason';

export const RESET_ACTION = 'RESET';

const reducer = {
  auth: authReducer,
  order: orderReducer,
  trip: tripReducer,
  orders: ordersReducer,
  trips: tripsReducer,
  orderChats: orderChatsReducer,
  tripChats: tripChatsReducer,
  notification: notificationReducer,
  orderChatStatus: orderChatStatusReducer,
  tripChatStatus: tripChatStatusReducer,
  acceptedOrder: acceptedOrderReducer,
  requestedOrder: requestedOrderReducer,
  reviews: reviewsReducer,
  orderHistory: orderHistoryReducer,
  tripHistory: tripHistoryReducer,
  reason: reasonReducer,
//   toast: toastReducer,
};

export const initialState = {
  auth: authInit,
  order: orderInit,
  trip: tripInit,
  orders: ordersInit,
  trips: tripsInit,
  orderChats: orderChatsInit,
  tripChats: tripChatsInit,
  notification: notificationInit,
  orderChatStatus: orderChatStatusInit,
  tripChatStatus: tripChatStatusInit,
  acceptedOrder: acceptedOrderInit,
  requestedOrder: requestedOrderInit,
  reviews: reviewsInit,
  orderHistory: orderHistoryInit,
  tripHistory: tripHistoryInit,
  reason: reasonInit,
//   toast: toastInit,
};

const combinedReducers = combineReducers(reducer);

export const reducers = (state, action) => {
  let newState = state;
  if (action.type === RESET_ACTION) {
    newState = initialState;
  }
  return combinedReducers(newState, action);
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['notification'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = getDefaultMiddleware({ serializableCheck: false });

export const store = configureStore({ reducer: persistedReducer, middleware });

export const persistor = persistStore(store);
