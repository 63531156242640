import { useEffect, useState, useCallback } from 'react';
import {
  notificationCount,
} from 'api';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { setSeenCount } from 'state/slices/notification';
import notificationSelector from 'state/selectors/notification';
import useRequest from '../useRequest';

export default function useGetCount() {
  const { isOnline, isAuthLoaded } = useAuth();
  const { doAuthRequest } = useRequest({ url: null });
  const [count, setcount] = useState(null);
  const { seenCount } = useSelector(notificationSelector);
  const dispatch = useDispatch();

  const getNotificationCount = useCallback(async () => {
    const { data } = await doAuthRequest(notificationCount(), {}, 'get', true)
      .catch((err) => console.log(err.stack));
    setcount(data);
    dispatch(setSeenCount(data));
    console.log(data);
    return count;
  }, [seenCount]);

  useEffect(() => {
    if (isAuthLoaded && isOnline && count === null) getNotificationCount();

    // return () => false;
  }, [count]);

  return seenCount;
}
