import { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import useAuth from 'hooks/useAuth';
import useStatusUpdate from './useStatusUpdate';

const UseSysSocket = () => {
  const [sysMsg, setSysMsg] = useState([]);
  const [notificationCount, setnotificationCount] = useState(null);
  // console.log('🚀 ~ file: useSysSocket.js ~ line 7 ~ UseSysSocket ~ sysMsg', sysMsg);
  const socketRef = useRef();
  const { userToken } = useAuth();
  const updateStatus = useStatusUpdate();
  // const tokenRef = useRef();

  //   const onMessageSubmit = (message, orderID, tripID, type = 'text') => {
  //     socketRef.current.emit('sysMsg-message', message, userToken, orderID, tripID, type, '5');
  //   };
  // console.log('token here', getToken());

  const clear = () => {
    setnotificationCount(0);
  };

  useEffect(
    () => {
      const connectionOptions = {
        'force new connection': true,
        reconnectionAttempts: 'Infinity',
        timeout: 10000,
        transports: ['websocket'],
      };
      const socketUrl = process.env.REACT_APP_SYSTEMMSG_SERVER;
      // console.log('🚀 ~ file: useSysSocket.js ~ line 25 ~ UseSysSocket ~ socketUrl', socketUrl);

      // socketRef.current = io(`wss://packrit.com/sysMsg?token=${userToken}`, connectionOptions, {
      //   path: '/socket.io/sysMsg',
      // });

      socketRef.current = io(`${socketUrl}?token=${userToken}`, connectionOptions, {
        path: '/socket.io/SystemMessage',
      });
      socketRef.current.on('system-message', (data) => {
        setSysMsg([...sysMsg, data]);
        updateStatus(data);
        setnotificationCount(data.count);
      });

      socketRef.current.on('connect', () => {
        // console.log('connected to :', socketRef.current.id); // x8WIv7-mJelg7on_ALbx
      });

      socketRef.current.on('disconnect', () => {
        // console.log('disconnected to :', socketRef.current.id); // undefined
      });

      return () => socketRef.current.disconnect();
    }, [sysMsg],
  );

  return { sysMsg, notificationCount, clear };
};

export default UseSysSocket;
