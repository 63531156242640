import React from 'react';
import useQueryString from 'hooks/useQueryString';
import Button from '@material-ui/core/Button';

const ForgotPasswordButton = ({ ...rest }) => {
  const [, setParams] = useQueryString();
  const handleClick = async () => {
    setParams({ forgotPassword: true });
  };
  return (
    <Button {...rest} onClick={handleClick}>Forgot Password ?</Button>
  );
};

export default ForgotPasswordButton;
