export var primary = "#1d3a49"
export var primaryLight = "#8a8ba7"
export var primaryDark = "#09142a"
export var secondary = "#ec231d"
export var secondaryLight = "#ec541d"
export var inheritDefault1 = "#fefefe"
export var inheritDefault2 = "#f8f9ff"
export var linkColor = "#070919"
export var second = "#070919"
export var indigo = "#7420ff"
export var purple = "#793de6"
export var pink = "#fc26a4"
export var red = "#f83245"
export var orange = "#f4772e"
export var yellow = "#ffc926"
export var green = "#1bc943"
export var teal = "#18e1a5"
export var cyan = "#27dcf3"
export default {"primary":"#1d3a49","primaryLight":"#8a8ba7","primaryDark":"#09142a","secondary":"#ec231d","secondaryLight":"#ec541d","inheritDefault1":"#fefefe","inheritDefault2":"#f8f9ff","linkColor":"#070919","second":"#070919","indigo":"#7420ff","purple":"#793de6","pink":"#fc26a4","red":"#f83245","orange":"#f4772e","yellow":"#ffc926","green":"#1bc943","teal":"#18e1a5","cyan":"#27dcf3"}
