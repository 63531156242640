// eslint-disable-next-line import/prefer-default-export
import { getMonth } from './getMonth';

// eslint-disable-next-line import/prefer-default-export
export const convertDate = (date) => {
  const [
    travelDay,
    travelMonth,
    travelYear,
  ] = date.split('/');
  return `${travelDay} ${getMonth(travelMonth - 1)} ${travelYear}`;
};
