/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
// import { set } from 'dot-prop-immutable';
// My Order History
export const initialState = { data: [] };

const slice = createSlice({
  name: 'orderHistory',
  initialState,
  reducers: {
    setOrderHistory(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    addOrder(state, action) {
      const { payload } = action;

      return { ...state, data: [...state.data, payload] };
    },
    clearOrderHistory(state) {
      return { ...state, order: null };
    },
  },
});

export const {
  setOrderHistory, addOrder, clearOrderHistory,
} = slice.actions;

export default slice.reducer;
