/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React from 'react';
import {
  TextField, InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import useRequest from 'hooks/useRequest';
import useAuth from 'hooks/useAuth';
import useRedirect from 'hooks/useRedirect';
// import { checkNumber } from 'api';
// import { get } from 'dot-prop-immutable';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { PhoneAndroidOutlined, LockOutlined, ErrorSharp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton } from 'components/buttons/buttons';

import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';

import DialCodesDropDown from './dialCodeDropDown';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function Login() {
  const classes = useStyles();
  // const { doRequest, errors: remoteError } = useRequest({
  //   method: 'get',
  //   onSuccess: false,
  // });

  const validationSchema = yup.object({
    mobile: yup
      .number()
      .typeError('Invalid Mobile No.')
      // .test('length', 'Invalid Mobile No.', (num) => (num ? num.toString().length > 6 : 0))
      .required('Mobile No is required'),
    // .test('isValidNumber', 'Not a valid number', async (num) => {
    //   const { valid } = await doRequest(checkNumber(num, values
    // .dialcode ? values.dialcode.code : ''));
    //   return valid;
    // }),
    password: yup
      .string('Enter your password')
      // .min(5, 'Atleast 5 characters are required')
      .required('Password is required'),

  });
  const { signIn } = useAuth();
  const [redirectTo] = useRedirect();

  const {
    handleSubmit, values, handleChange, touched, errors, isSubmitting, setFieldValue,
  } = useFormik({
    initialValues: {
      mobile: '',
      password: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (valz, {
      validate, resetForm, setFieldError, setSubmitting, setErrors,
    }) => {
      if (validate) validate(valz);

      const {
        mobile, password,
      } = valz;

      signIn({
        mobile,
        password,

      })

        .then(() => {
          resetForm();
          redirectTo('dashboard');
        })
        .catch(({ message }) => {
          setSubmitting(false);
          setErrors({ mobile: message });
          setFieldError(message.includes('Password') ? 'password' : 'mobile', message);
        });
    },
  });
  console.log(errors);
  console.log(values);
  return (
    // eslint-disable-next-line react/jsx-no-duplicate-props
    <form className="pt-3" className={classes.root} noValidate autoComplete="off" className="align-self-center d-flex flex-column justify-content-around" onSubmit={handleSubmit}>
      <div className={`position-relative ${errors.mobile ? 'error-field' : ''}`}>
        <PhoneInput
          id="mobile"
          international
          country="pk"
          inputProps={{
            name: 'mobile',
            required: true,
            autoFocus: true,
            className: errors.mobile ? 'form-control error' : 'form-control',
          }}
          value={values.mobile}
          enableSearch
          onChange={(val) => setFieldValue('mobile', `+${val}`)}
          error={touched.mobile && Boolean(errors.mobile)}
          helperText={touched.mobile && errors.mobile}

        />
        <PhoneAndroidOutlined className="endicon" />
        {errors.mobile && <p className="error-msg" id="password-helper-text">{errors.mobile}</p>}
      </div>
      {/* <TextField
        label="Mobile No"
        id="mobile"
        className="mt-3"
        InputProps={{
          startAdornment: <InputAdornment position="start"><DialCodesDropDown
          id="dialcode" setFieldValue={setFieldValue} /></InputAdornment>,
          endAdornment: <InputAdornment position="end"><PhoneAndroidOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.mobile}
        onChange={handleChange}
        error={touched.mobile && Boolean(errors.mobile)}
        helperText={touched.mobile && errors.mobile}
      /> */}
      <TextField
        id="password"
        label="Password"
        type="password"
        className="mt-3"
        autoComplete="current-password"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.password}
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
      />

      <SecondaryButton
        className="mt-5 py-3"
        endIcon={<DoubleArrowOutlinedIcon />}
        type="submit"
        disabled={isSubmitting}
      >
        Log In
      </SecondaryButton>

    </form>
  );
}

export default Login;
