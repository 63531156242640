import { createSlice } from '@reduxjs/toolkit';
// import { set } from 'dot-prop-immutable';

export const initialState = { data: [] };

const slice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setReviews(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearReviews(state) {
      return { ...state };
    },
  },
});

export const { setReviews, clearReviews } = slice.actions;

export default slice.reducer;
