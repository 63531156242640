import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearUser,
} from 'state/slices/auth';

export default function useSignOut() {
  const dispatch = useDispatch();
  const signOut = useCallback(() => {
    localStorage.clear();
    localStorage.setItem('persist:root', Date.now());
    return dispatch(clearUser());
  }, [dispatch]);

  return signOut;
}
