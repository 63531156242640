/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import { set } from 'dot-prop-immutable';
// Matching Order
export const initialState = { data: [] };

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrderByTrip(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearOrderByTrip(state) {
      return { ...state, order: null };
    },
    setOrderById(state, action) {
      const { payload } = action;

      return { ...state, data: [...state.data, payload] };
    },
    updateOrdersChat(state, action) {
      const { orderID, data } = action.payload;
      const order = state.data.find((ord) => ord._id === orderID);
      if (order) {
        const chatObj = order.chat.map((ch) => {
          if (ch.trip._id === data.trip._id) return data;

          return ch;
        });
        if (order.chat.length === 0) order.chat.push(data);
        else {
          try {
            const ftrip = set(order, 'chat', chatObj);
            return {
              ...state,
              data: state.data.map((trip) => {
                if (trip._id === ftrip._id) return { ...trip, ...ftrip };
                return trip;
              }),
            };
          } catch (error) {
            console.log(error);
          }
        }
      }
      return state;
    },
    emptyOrdersChat(state, action) {
      const orderID = action.payload;

      if (state.data.length > 0) {
        return {
          ...state,
          data: state.data.map((item) => {
            // Find the item with the matching id
            if (item._id === orderID) {
              // Return a new object
              return { ...item, chat: [] };
            }
            return item;
          }),
        };
      }
      return state;
    },
  },
});

export const {
  setOrderByTrip, clearOrderByTrip, setOrderById, updateOrdersChat, emptyOrdersChat,
} = slice.actions;

export default slice.reducer;
