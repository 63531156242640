import React from 'react';
import { Avatar, Typography } from '@material-ui/core/';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  default: {
    width: '3.5rem',
    height: '3.5rem',

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    width: '100% !important',
    height: '100vh',
  },

  bigimage: {
    objectFit: 'contain',
    maxHeight: '90vh',
    maxWidth: '90vw',
  },
}));

export default function Thumbnail({
  image, className, label, alt, lcolor = 'primary', inlarge = false,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
    console.log('🚀 ~ file: thumbnail.js ~ line 28 ~ handleToggle ~ open', open);
  };
  return (
    <div className="p-0 m-0 d-flex flex-column justify-content-center image">

      <Avatar alt={alt} src={image} variant="rounded" onClick={inlarge && handleToggle} className={className || classes.default} />
      {label
        ? (
          <Typography variant="h6" align="center" color={lcolor} className="text-capitalize">
            {label}
          </Typography>
        ) : ''}
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <img src={image} alt="inlarge" className={classes.bigimage} />
      </Backdrop>
    </div>
  );
}
