const landing = '/main';
const home = '/';
const signOut = '/sign-out';
const signIn = '/sign-in';
const signUp = '/sign-up';
const resetPassword = '/recover-account';
const addOrder = '/order/add';
const addOrderSend = '/order/add-send';
const orderDetail = '/order/:id/detail';
const orderTraveller = '/order/traveller';
const orderChat = '/order/chat';
const dashboard = '/dashboard';
const myOrders = '/order/my';
const addTrip = '/trip/add';
const myTrip = '/trip/list';
const tripDetail = '/trip/detail/:id';
const tripOrders = '/trip/orders';
const tripChat = '/trip/chat';
const orderStatus = '/order/:id/status';
const myHistory = '/history/my';
const orderHistory = '/history/order/:id';
const tripHistory = '/history/trip/:id';
const termCondition = '/term&condition';
const privacyPolicy = '/privacyPolicy';
// eslint-disable-next-line import/prefer-default-export
export const initialPages = (
  {
    home,
    signOut,
    signIn,
    landing,
    resetPassword,
    signUp,
    termCondition,
    privacyPolicy,
  }
);

export const authPages = (
  {
    dashboard,
    addOrder,
    addOrderSend,
    orderDetail,
    orderTraveller,
    orderChat,
    addTrip,
    tripDetail,
    myTrip,
    tripOrders,
    tripChat,
    myOrders,
    orderStatus,
    myHistory,
    orderHistory,
    tripHistory,
  }
);
