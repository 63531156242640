/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { ReactComponent as Megaphone } from 'assets/megaphone.svg';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

import { authPages, initialPages } from '../../routes/url';

const menu = [
  {
    text: 'Home',
    icon: <HomeOutlinedIcon />,
    link: authPages.dashboard,
  },
  {
    text: 'History',
    icon: <HistoryOutlinedIcon />,
    link: authPages.myHistory || '#',
  },
  /* {
    text: 'About App',
    icon: <InfoOutlinedIcon />,
    link: initialPages.signUp || '#',
  },
  {
    text: 'Get Help',
    icon: <HelpOutlineOutlinedIcon />,
    link: initialPages.signUp || '#',
  },
  {
    text: 'Advertise with Us',
    icon: <SvgIcon component={Megaphone} viewBox="-3 0 28 20" />,
    link: initialPages.signUp || '#',
  },
  {
    text: 'Term & Conditions',
    icon: <AssignmentOutlinedIcon />,
    link: initialPages.signUp || '#',
  }, */
  // {
  //   text: 'Logout',
  //   icon: <PowerSettingsNewOutlinedIcon />,
  //   link: 'logout',
  // },
];
export default menu;
