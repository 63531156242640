/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { useParams, useLocation } from 'react-router';

// import getOrderById from 'hooks/order/getOrderById';
import useTrip from 'hooks/trip/useTrip';

// import getTripById from 'hooks/trip/getTripById';
import useOrder from 'hooks/order/useOrder';

export default function useSearch(searchValue) {
  const { id } = useParams();
  // const [order] = getOrderById(id);
  const [,,, getOrderByTrip,,,,,,,, getOrder] = useOrder();
  const [,,,, getTripByOrder, getTrip] = useTrip();
  const { pathname } = useLocation();
  // const [trip] = getTripById(id);

  const searchtraveller = useCallback(async () => {
    const order = await getOrder(id);
    if (order) {
      const {
        from,
        to,
        _id,
        fromDate,
        toDate,
      } = order;

      getTripByOrder(1, from, to, fromDate, toDate, _id, searchValue);
    }
    // console.log('🚀 ~ file: searchbox.js ~ line 57 ~ handleSubmit ~ order', order);
    // console.log('getOrder', await getOrder(id));
  }, [getOrder, getTripByOrder, id, searchValue]);

  const searchOrderer = useCallback(async () => {
    const trip = await getTrip(id);
    if (trip) {
      const {
        departure,
        arrival,
        _id,
        travelDate,

      } = trip;

      getOrderByTrip(1, departure, arrival, travelDate, _id, searchValue);
    }
  }, [getOrderByTrip, getTrip, id, searchValue]);
  const Searchit = useCallback(() => {
    // console.log(location);
    if (pathname.includes('order')) searchtraveller();
    if (pathname.includes('trip')) searchOrderer();
  });
  return Searchit;
}
