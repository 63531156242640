import React from 'react';
import useAlert from 'hooks/useAlert';
import {
  Container, AppBar, Toolbar, Typography,
} from '@material-ui/core';
import SearchBox from 'components/searchbox';
import SearchBtn from 'components/buttons/searchBtn';
import MenuBtn from 'components/buttons/menuBtn';
import NotificationBtn from 'components/buttons/notificationBtn';
import './index.scss';

const Index = ({
  children, title, className, istopMenu = false, isSearch = false,
}) => {
  const [,,,, displayAlert] = useAlert();

  return (
    <>
      <AppBar position="static" className="p-0 m-0">
        <Toolbar variant="dense" className="d-flex justify-content-center py-4">
          {istopMenu && (
          <>
            <MenuBtn
              className="m-0 leftTop"
              color="inherit"
            />
            {isSearch && (
            <div className="teal-search_button">

              <SearchBtn color="inherit" />
            </div>
            )}
            <NotificationBtn
              className="m-0 rightTop"
              color="inherit"
            />

          </>
          )}
          <Typography variant="h3" color="inherit">
            {title}
          </Typography>
          {isSearch && (
          <div className="teal-search_box mt-2">
            <SearchBox />
          </div>
          )}

        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth={false} className={className || 'main-container--teal px-lg-5 px-xs-2 pt-5'}>

        {children}
        {displayAlert}
      </Container>
    </>
  );
};

export default Index;
