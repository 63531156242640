import React, { useState, useEffect } from 'react';
import { PinkBox } from 'components/layouts';
import {
  Box, Typography, Link, Paper,
} from '@material-ui/core';
import useRedirect from 'hooks/useRedirect';
import { Link as RouterLink } from 'react-router-dom';
import useTrip from 'hooks/trip/useTrip';
import SkeletonCard from 'components/skeletonCard';
import TripCard from './tripCard';
import './index.scss';
// import PropTypes from 'prop-types';

const MyTrips = () => {
  const [mytrips, setmytrips] = useState(null);
  const defaultProps = {
    bgcolor: 'primary.main',
    m: 1,
    border: 1,
    style: { height: '5rem', backgroundColor: 'primary.main' },
    backgroundColor: 'primary.main',
    className: 'dashboard_tealTop',
    color: 'primary.light',
  };
  const [, routeTo] = useRedirect();
  const [,, getMyTrip] = useTrip();
  useEffect(() => {
    getMyTrip(1, 0, 3).then(({ data }) => {
      setmytrips(data);
    }).catch((err) => console.log(err));
  }, []);
  return (
    <PinkBox>
      <Link component={RouterLink} to={routeTo('myTrip')} color="primary">
        <Box borderColor="primary.main" {...defaultProps}>
          <Typography variant="h4" color="inherit" align="center">
            My Trips
          </Typography>
        </Box>
      </Link>
      {Array.isArray(mytrips) ? mytrips.map((trip) => <TripCard data={trip} />) : (
        <>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </>
      )}
      {mytrips && mytrips.length < 1
          && (
          <Paper className="order_empty m-2" elevation={2}>
            <Typography variant="body2" color="Primary" align="center">No trip found.</Typography>
          </Paper>
          )}
      {mytrips && mytrips.length > 0
      && (
      <Typography variant="body2" color="Primary" align="center" className="flex-center-end">
        <Link component={RouterLink} to={routeTo('myTrip')} color="primary">
          More
        </Link>
      </Typography>
      )}
    </PinkBox>
  );
};

MyTrips.propTypes = {

};

export default MyTrips;
