/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { set } from 'dot-prop-immutable';
import { createSlice } from '@reduxjs/toolkit';
// macthing trip for order
export const initialState = { data: [] };

const slice = createSlice({
  name: 'trips',
  initialState,
  reducers: {
    setTripByOrder(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearOrder(state) {
      return { ...state, data: [] };
    },

    updateTripChat(state, action) {
      const { tripID, data } = action.payload;
      const trip = state.data.find((trp) => trp._id === tripID);
      if (trip) {
        const chatObj = trip.chat.map((ch) => {
          if (ch.order._id === data.order._id) return data;

          return ch;
        });

        if (trip.chat.length === 0) trip.chat.push(data);
        else {
          try {
            const fOrder = set(trip, 'chat', chatObj);
            return {
              ...state,
              data: state.data.map((ord) => {
                if (ord._id === fOrder._id) return { ...ord, ...fOrder };
                return ord;
              }),
            };
          } catch (error) {
            console.log(error);
          }
        }
      }
      return state;
    },

    emptyTripsChat(state, action) {
      const tripID = action.payload;

      if (state.data.length > 0) {
        return {
          ...state,
          data: state.data.map((item) => {
            // Find the item with the matching id
            if (item._id === tripID) {
            // Return a new object
              return { ...item, chat: [] };
            }
            return item;
          }),
        };
      }
      return state;
    },
  },
});

export const {
  setTripByOrder, clearOrder, updateTripChat, emptyTripsChat,
} = slice.actions;

export default slice.reducer;
