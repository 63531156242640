const API_BASE_URL = process.env.REACT_APP_API_BASE;

export const signin = () => (`${API_BASE_URL}/signin`);
export const signup = () => (`${API_BASE_URL}/signup`);
export const recoverAccount = () => (`${API_BASE_URL}/recoverAccount`);
export const checkNumber = (phone, countryCode) => ('http://apilayer.net/api/validate?'
  + 'access_key=3e8f56c371a1026ab135f097829b45fe'
  + `&number=${phone}`
  + `&country_code=${countryCode}`
  + '&format=1');
export const getCountryCallingCode = () => ('https://ipapi.co/country_calling_code/');
export const forgotPassword = () => (`${API_BASE_URL}/forgetPassword`);
export const facebookLogin = () => ('https://packrit.com/auth/facebook');
export const googleLogin = () => ('https://packrit.com/auth/google');
export const vserifyphone = () => (`${API_BASE_URL}/verifyPhoneToken`);
export const resendSMSToken = () => (`${API_BASE_URL}/resendSMSToken`);
export const newOrder = () => (`${API_BASE_URL}/order`);
export const addTrip = () => (`${API_BASE_URL}/trip`);
export const getTripById = (id) => (`${API_BASE_URL}/trip/${id}`);
export const getMyTrip = (page, isHistory, limit = '30') => (`${API_BASE_URL}/trip?page=${page}&limit=${limit}&q=${isHistory}&sort=created_at&order=desc`);
export const getMyOrders = (page, isHistory, limit = '30') => (`${API_BASE_URL}/order?page=${page}&limit=${limit}&q=${isHistory}&sort=created_at&order=desc`);
export const getOrderById = (id) => (`${API_BASE_URL}/order/${id}`);
export const searchOrderByTrip = (page, destination, arrival, travelDate, tripId, ordererName) => (`${API_BASE_URL}/order?page=${page}&limit=30&search=${destination.replace(' ', '')}|${arrival.replace(' ', '')}|${travelDate}|${tripId}${(ordererName ? `|${ordererName}` : '')}&sort=created_at&order=desc`);
export const getCityCountry = (val) => (`${API_BASE_URL}/cityCountry?page=1&limit=5&q=${val}&sort=cityCountry&order=asc`);
export const uploadImage = () => (`${API_BASE_URL}/imageUpload`);
export const receiverUploadImage = () => (`${API_BASE_URL}/receiverImageUpload`);
export const searchTripByOrderId = (page, destination, arrival, fromDate, toDate, orderId, travellerName) => (`${API_BASE_URL}/trip?page=${page}&limit=30&search=${destination.replace(' ', '')}|${arrival.replace(' ', '')}|${fromDate}|${toDate}|${orderId}${(travellerName ? `|${travellerName}` : '')}&sort=created_at&order=desc`);
export const changePassword = () => (`${API_BASE_URL}/user/changePassword`);
export const chatRequest = () => (`${API_BASE_URL}/chatRequest`);
export const chatAccept = () => (`${API_BASE_URL}/chatAccept`);
export const chatReject = () => (`${API_BASE_URL}/chatReject`);
export const orderChat = (page, orderId) => (`${API_BASE_URL}/orderChat?page=${page}&limit=30&q=${orderId}&sort=created_at&order=desc`);
export const tripChat = (page, tripId) => (`${API_BASE_URL}/tripChat?page=${page}&limit=30&q=${tripId}&sort=created_at&order=desc`);
export const getAllNotification = () => (`${API_BASE_URL}/getAllNotification?page=1&limit=50&sort=created_at&order=desc`);
export const orderChatByOrderId = (page, orderId) => (`${API_BASE_URL}/orderChat?page=${page}&limit=30&q=${orderId}&sort=created_at&order=desc`);
export const tripChatByTripId = (page, tripId) => (`${API_BASE_URL}/tripChat?page=${page}&limit=30&q=${tripId}&sort=created_at&order=desc`);
export const getOrderChatStatusById = (orderId, tripId) => (`${API_BASE_URL}/orderChatStatus/${orderId}/${tripId}`);
export const getTripChatStatusById = (tripId, orderId) => (`${API_BASE_URL}/tripChatStatus/${tripId}/${orderId}`);
export const placeOrder = () => (`${API_BASE_URL}/placeOrder`);
export const chatImageUpload = () => (`${API_BASE_URL}/chatImageUpload`);
export const chatAudioUpload = () => (`${API_BASE_URL}/chatAudioUpload`);
export const updateOrderStatus = (orderId) => (`${API_BASE_URL}/orderStatus/${orderId}`);
export const acceptedOrdersByTripId = (page, tripId, isHistory) => (`${API_BASE_URL}/tripOrder?page=${page}&limit=30&q=${tripId}|${isHistory}&sort=created_at&order=desc`);
export const verifyCode = (orderId) => (`${API_BASE_URL}/verifyCode/${orderId}`);
export const feedback = (orderId) => (`${API_BASE_URL}/feedback/${orderId}`);
export const getAllReviews = (page, userId) => (`${API_BASE_URL}/reviews?page=${page}&limit=30&q=${userId}&sort=created_at&order=desc`);
export const getChatList = (orderId, tripId) => (`${API_BASE_URL}/chatList/${orderId}/${tripId}`);
export const getUserbyId = (userId) => (`${API_BASE_URL}/user/${userId}`);
export const profileImageUpload = () => (`${API_BASE_URL}/profileImageUpload`);
export const feedbackStatus = () => (`${API_BASE_URL}/feedbackStatus`);
export const readAllMessage = (orderId, tripId) => (`${API_BASE_URL}/readAllMessage/${orderId}/${tripId}`);
export const clientfeedback = () => (`${API_BASE_URL}/appFeedback`);
export const getBlockReason = () => (`${API_BASE_URL}/blockReason`);
export const blockUser = (id) => (`${API_BASE_URL}/blockUser/${id}`);
export const notificationCount = () => (`${API_BASE_URL}/getCountNotification`);
export const notificationClearAll = () => (`${API_BASE_URL}/clearAllNotification`);
export const notificationSeenAll = () => (`${API_BASE_URL}/seenAllNotification`);
export const updateNotificationReadStatus = (id) => (`${API_BASE_URL}/updateNotificationReadStatus/${id}`);
