import React from 'react';
import {
  Container, // Box, Typography, Avatar,
} from '@material-ui/core';
import './index.scss';

const simple = ({ children }) => (
  <Container component="main" maxWidth={false} className="main-container py-5 text-center">
    {children}
  </Container>
);

export default simple;
