import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThemeProvider } from '@material-ui/styles';
// import * as Modals from 'components/modals';
import Loader from 'components/loader';
import Routes from './routes';
import MuiTheme from './theme';
import './assets/core/base.scss';

const ForgotPassword = lazy(() => import('components/modals/forgotPassword'));
const TermsCondition = lazy(() => import('components/modals/termCondition'));
const ChangePassword = lazy(() => import('components/modals/changePassword'));
const Notification = lazy(() => import('components/modals/notification'));
const OrderChatRequest = lazy(() => import('components/modals/OrderChatRequest'));
const TripChatRequest = lazy(() => import('components/modals/TripChatRequest'));
const OrderStatus = lazy(() => import('components/modals/orderStatus'));
const EditProfile = lazy(() => import('components/modals/editProfile'));
const ClientFeedback = lazy(() => import('components/modals/clientFeedback'));
const ReportUser = lazy(() => import('components/modals/reportUser'));

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Packr | Anything, Anywhere</title>
        <meta name="title" content="Packr | Anything, Anywhere" data-react-helmet="true" />
        <meta name="description" content="Anything, Anywhere" data-react-helmet="true" />
      </Helmet>
      <BrowserRouter basename="/">
        <ThemeProvider theme={MuiTheme}>
          <CssBaseline />

          <Suspense fallback={<Loader />}>
            <Routes />
            <ForgotPassword />
            <TermsCondition />
            <ChangePassword />
            <Notification />
            <OrderChatRequest />
            <TripChatRequest />
            <OrderStatus />
            <EditProfile />
            <ClientFeedback />
            <ReportUser />
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>

    </div>
  );
}

export default App;
