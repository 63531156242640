import { createSlice } from '@reduxjs/toolkit';

export const initialState = { orderChatStatus: null };

const slice = createSlice({
  name: 'orderChatStatus',
  initialState,
  reducers: {
    setOrderChatStatus(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearOrderChatStatus(state) {
      return { ...state, orderChatStatus: null };
    },
  },
});

export const { setOrderChatStatus, clearOrderChatStatus } = slice.actions;

export default slice.reducer;