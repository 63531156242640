import React from 'react';
import { TealLayout } from 'components/layouts';
import AddTripForm from 'components/forms/addTrip';

const addTrip = () => (
  <TealLayout title="Add A Trip" istopMenu>
    <div className="d-flex justify-content-around flex-column w-md-50 mt-0 h-75">
      <AddTripForm />
    </div>
  </TealLayout>
);

export default addTrip;
