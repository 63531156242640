import { createSlice } from '@reduxjs/toolkit';

export const initialState = { orderChats: null };

const slice = createSlice({
  name: 'orderChats',
  initialState,
  reducers: {
    setOrderChat(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearOrderChat(state) {
      return { ...state, orderChat: null };
    },
    updateOrderChatMsg(state, action) {
      const { payload } = action;
      return {
        ...state,
        data: state.data.map((item) => {
        // Replace the item
          // eslint-disable-next-line no-underscore-dangle
          if (item._id === payload.chatId) {
            return {
              ...item,
              message: payload.message,
              type: 'text',
              created_at: Date.now(),
              unReadCount: '0',
            };
          }

          // Leave every other item unchanged
          return item;
        }),
      };
    },
    updateReadCount(state, action) {
      const { payload } = action;
      console.log('🚀 ~ file: orderChats.js ~ line 40 ~ updateReadCount ~ payload', payload);
      return {
        ...state,
        data: state.data.map((item) => {
        // Replace the item
          // eslint-disable-next-line no-underscore-dangle
          if (item._id === payload.chatId) {
            return {
              ...item,
              unReadCount: '0',
            };
          }

          // Leave every other item unchanged
          return item;
        }),
      };
    },
  },
});

export const {
  setOrderChat, clearOrderChat, updateOrderChatMsg, updateReadCount,
} = slice.actions;

export default slice.reducer;
