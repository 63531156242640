import React, { useState, useEffect } from 'react';
import { PinkBox } from 'components/layouts';
import {
  Box, Typography, Link, Paper,
} from '@material-ui/core';
import useRedirect from 'hooks/useRedirect';
import useOrder from 'hooks/order/useOrder';
import { Link as RouterLink } from 'react-router-dom';
import SkeletonCard from 'components/skeletonCard';
import OrderCard from './orderCardnew';
import './index.scss';

// import PropTypes from 'prop-types';

const MyOrders = () => {
  const [myorders, setmyorders] = useState(null);
  const defaultProps = {
    bgcolor: 'primary.main',
    m: 1,
    border: 1,
    style: { height: '5rem', backgroundColor: 'primary.main' },
    backgroundColor: 'primary.main',
    className: 'dashboard_tealTop',
    color: 'primary.light',
  };
  const [, routeTo] = useRedirect();
  const [,, getOrders] = useOrder();
  useEffect(() => {
    getOrders(1, 0, 3).then(({ data }) => {
      setmyorders(data);
    }).catch((err) => console.log(err));
  }, []);

  // if (myorders.length < 1) return <p>opps no record found.</p>;

  return (
    <PinkBox>
      <Link component={RouterLink} to={routeTo('myOrders')} color="primary">
        <Box borderColor="primary.main" {...defaultProps}>
          <Typography variant="h4" color="inherit" align="center">
            My Orders
          </Typography>
        </Box>
      </Link>
      {Array.isArray(myorders) && Boolean(myorders)
        ? myorders.map((order) => <OrderCard data={order} />) : (
          <>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </>
        )}

      {myorders && myorders.length < 1
          && (
          <Paper className="order_empty m-2" elevation={2}>
            <Typography variant="body2" color="Primary" align="center">No order found.</Typography>
          </Paper>
          )}
      {myorders && myorders.length > 0
          && (
          <Typography variant="body2" color="Primary" align="center" className="flex-center-end">
            <Link component={RouterLink} to={routeTo('myOrders')} color="primary">
              More
            </Link>
          </Typography>
          )}

    </PinkBox>
  );
};

MyOrders.propTypes = {

};

export default MyOrders;
