import React from 'react';
import useQueryString from 'hooks/useQueryString';
import useShowCount from 'hooks/notification/showCount';

import {
  IconButton, Badge,
} from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

const NotificatoionButton = ({ ...rest }) => {
  const [, setParams] = useQueryString();
  const finalCount = useShowCount();
  // const [finalCount, setfinalCount] = useState(null);

  const handleClick = async () => {
    setParams({ notification: true });
  };

  console.log('🚀 ~ file: notificationBtn.js ~ line 13 ~ NotificatoionButton ~ finalCount', finalCount);
  return (
  // <Button {...rest} onClick={handleClick}>Change Password</Button>
    <IconButton
      edge="end"
      aria-label="menu"
      onClick={handleClick}
      {...rest}
    >
      <Badge badgeContent={finalCount} invisible={!finalCount} color="secondary">
        <NotificationsNoneIcon />
      </Badge>
    </IconButton>
  );
};

export default NotificatoionButton;
