/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-dupe-keys */
import React from 'react';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {
  IconButton, SvgIcon, Typography, Link,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Thumbnail from 'components/thumbnail';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useAuth from 'hooks/useAuth';
import { NavLink as RouterLink } from 'react-router-dom';
import { ReactComponent as MenuIcon } from 'assets/menu.svg';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import LogoutButton from 'components/buttons/logoutBtn';
import EditProfileBtn from 'components/buttons/editProfileBtn';
import ClientFeedbackBtn from 'components/buttons/clientFeedbackBtn';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import Menu from './menu';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    color: '#fff',
    background: 'rgb(111,120,145)',
    background: 'linear-gradient(0deg, rgba(111,120,145,1) 0%, rgba(31,59,75,1) 100%)',
    height: '100vh',
    padding: theme.spacing(5, 3),
    '& .MuiListItemIcon-root': {
      color: '#fff',
      minWidth: '36px',
    },
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    '& MuiDrawer-paper': {
      background: 'rgb(111,120,145)',
      background: 'linear-gradient(0deg, rgba(111,120,145,1) 0%, rgba(31,59,75,1) 100%)',
    },
  },

}));

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(open);
  };

  const { isAuthLoaded, getUserDetail, user } = useAuth();
  // eslint-disable-next-line no-unused-vars,
  const [detail, setdetail] = React.useState({ userName: null });

  React.useEffect(() => {
    if (detail && detail.userName === null && isAuthLoaded) {
      getUserDetail()
        .then(({ userName, profileImage }) => setdetail({ userName, profileImage }))
        .catch((err) => console.log(err));
    }
    if (Boolean(user)
      && (user.userName !== detail.userName)) {
      setdetail({
        userName: user.userName,
        profileImage: user.profileImage,
      });
    }
    return () => {
      // Clean up the subscription
      console.log('unloaded');
    };
  }, [isAuthLoaded, user, state]);

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Thumbnail
        alt="Remy Sharp"
        image={detail.profileImage || ''}
        className="big-thumbnail border border-secondary ml-3"
        label=""
      />
      <Typography variant="h5" align="left" color="inherit" className="m-3 pl-1 text-capitalize">
        {detail.userName || ''}
      </Typography>
      <EditProfileBtn />
      <Divider className="mt-4" />
      <List>

        {Menu.map((item) => (
          <Link component={RouterLink} to={item.link} color="inherit">
            <ListItem button key={item.text}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          </Link>
        ))}
        <ClientFeedbackBtn>
          <ListItem button>
            <ListItemIcon><FeedbackOutlinedIcon /></ListItemIcon>
            <ListItemText primary="Feedback" />
          </ListItem>
        </ClientFeedbackBtn>
        <LogoutButton>
          <ListItem button>
            <ListItemIcon><PowerSettingsNewOutlinedIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </LogoutButton>
      </List>
      <Divider />

    </div>
  );

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)} edge="start" className="m-0" color="inherit" aria-label="menu">
        <SvgIcon component={MenuIcon} viewBox="-3 0 40 30" />
      </IconButton>
      <Drawer anchor="left" open={state} onClose={toggleDrawer(false)} classes={classes.drawer}>
        {list()}
      </Drawer>
    </div>
  );
}
