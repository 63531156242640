/* eslint-disable no-unused-vars */
import React from 'react';
// import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import YouTube from 'react-youtube';

const Hero = () => (
  <div className="pt-2 pt-md-5">
    <Grid container>
      <Grid item xs={12} sm={4}>
        {/* <Typography
          variant="button"
          display="block"
          className="text-uppercase font-weight-bold"
          color="textPrimar"
          gutterBottom
        >
          Benefits
        </Typography> */}

        <Typography variant="h2" align="left" color="inherit" gutterBottom>
          Get ultimate solution for your hassle free Package transfer
        </Typography>

        {/* <Typography variant="h5" align="left" color="inherit" gutterBottom>
          A platform where people can connect with
          travellers from any part of
          the world going to or comming from a city of your desire to send and receive packages.
        </Typography> */}
        <Box display="flex" justifyContent="space-evenly" marginTop={5}>
          <Box borderRadius="50%" className="landing-page--roundlabels">
            <Typography variant="h4" align="center" color="inherit" gutterBottom>
              0%
            </Typography>
            <Typography variant="body1" align="center" className="labelsmall" color="inherit" gutterBottom>
              Cost
            </Typography>
          </Box>
          <Box borderRadius="50%" className="landing-page--darkroundlabels">
            <Typography variant="h4" align="center" color="inherit" gutterBottom>
              10x
            </Typography>
            <Typography variant="caption" align="center" color="inherit" className="labelsmall" gutterBottom>
              Faster Delivery
            </Typography>
          </Box>
          <Box borderRadius="50%" className="landing-page--roundlabels">
            <Typography variant="h4" align="center" color="inherit" gutterBottom>
              100%
            </Typography>
            <Typography variant="caption" align="center" color="inherit" className="labelsmall" gutterBottom>
              Risk Free
            </Typography>
          </Box>
        </Box>
        <Typography variant="h4" align="left" color="inherit" className="mt-4" gutterBottom>
          Use your extra laguage space to earn a little extra.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} container justify="center">
        <YouTube videoId="vTlGWwmssYs" className="rounded landing-page_video landing-page_pinkshadow" />
      </Grid>
    </Grid>

  </div>

);

export default Hero;
