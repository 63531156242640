import { createSlice } from '@reduxjs/toolkit';
// My Trip
export const initialState = { data: [] };

const slice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    setTrip(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearTrip(state) {
      return { ...state, trip: null };
    },
    addTrip(state, action) {
      const { payload } = action;

      return { ...state, data: [...state.data, payload] };
    },
  },
});

export const { setTrip, clearTrip, addTrip } = slice.actions;

export default slice.reducer;
