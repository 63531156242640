/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { initialPages, authPages } from 'routes/url';

export default function useRedirect() {
  const history = useHistory();

  const redirectTo = useCallback((url, param = null) => {
    let purl = initialPages[url] ? initialPages[url] : authPages[url];
    if (param) {
      for (const ele in param) purl = purl.replace(`:${ele}`, param[ele]);
    }
    history.push(purl);
  });

  const routeTo = useCallback((url, param = null) => {
    let purl = initialPages[url] ? initialPages[url] : authPages[url];
    if (param) {
      for (const ele in param) purl = purl.replace(`:${ele}`, param[ele]);
    }
    return purl;
  });
  return [redirectTo, routeTo];
}
