import React from 'react';
import useQueryString from 'hooks/useQueryString';
import useAuth from 'hooks/useAuth';
import {
  Button,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

const EditProfileBtn = ({ ...rest }) => {
  const [, setParams] = useQueryString();
  const { getUserDetail } = useAuth();
  const handleClick = async () => {
    setParams({ editProfile: true });
    getUserDetail().then((res) => console.log(res));
  };
  return (
  // <Button {...rest} onClick={handleClick}>Change Password</Button>
    <Button
      variant="contained"
      color="primary"
      size="small"
      className="ml-3"
      startIcon={<SettingsIcon />}
      onClick={handleClick}
      {...rest}
    >
      Edit Profile
    </Button>
  );
};

export default EditProfileBtn;
