/* eslint-disable import/no-webpack-loader-syntax */
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
// eslint-disable-next-line import/no-unresolved
import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

// eslint-disable-next-line import/no-mutable-exports
let MuiTheme = createMuiTheme({
  palette: {
    text: {
      secondary: '#383838',
      primary: '#8a8ba7',
      light: '#fff',
    },
    primary: {
      main: vars.primary,
      light: vars.primaryLight,
      //       main: vars.primary,
      //       dark: '#002884',
      contrastText: '#fff',
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2,
    },
    secondary: {
      main: vars.secondary,
      contrastText: '#ffffff',
    },
    error: {
      main: vars.red,
    },
    success: {
      main: vars.green,
    },
    warning: {
      main: vars.orange,
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: '0.5rem',
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px',
      },
      arrow: {
        color: vars.second,
      },
    },

    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Metroplis',
      },
    },
  },
  typography,
});
MuiTheme = responsiveFontSizes(MuiTheme);

export default MuiTheme;

// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       light: '#757ce8',
//       main: vars.primary,
//       dark: '#002884',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff7961',
//       main: '#f44336',
//       dark: '#ba000d',
//       contrastText: '#000',
//     },
//   },
// });
// export default theme;
