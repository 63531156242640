import React from 'react';
import {
  Link, Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { TealLayout } from 'components/layouts';
import SignupForm from 'components/forms/signup';
import { initialPages } from 'routes/url';

const index = () => (
  <TealLayout title="Create Profile">
    <div className="d-flex justify-content-around flex-column w-md-50 mt-5 h-75">

      <SignupForm />
      <Typography variant="subtitle1" align="center" className="mt-5">
        Already have an account?
        {' '}

        <Link component={RouterLink} to={initialPages.signIn} color="secondary">
          Sign In
        </Link>
      </Typography>
      <Typography variant="subtitle1" align="center" className="mt-5">
        <Link component={RouterLink} to="/" color="secondary">
          Back To Home
        </Link>
      </Typography>
    </div>

  </TealLayout>
);

export default index;
