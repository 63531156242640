/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import DialogBase from 'components/modals/base';
import {
  Typography, AppBar, Toolbar, Paper,
} from '@material-ui/core';
import { AddFeedbackForm } from 'components/forms';
// import getAcceptedOrderById from 'hooks/order/getAcceptedOrderById';
// import useOrder from 'hooks/order/useOrder';
// import './index.scss';

const Index = ({
  open, handleClose, order, text,
}) => {
  // const [order] = getAcceptedOrderById(orderID, 'update');

  if (!order) return <></>;
  return (
    <DialogBase
      open={open}
      handleClose=""
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      className="chat-container"
      title={(
        <AppBar position="static" className="p-0 m-0">
          <Toolbar variant="dense" className="d-flex justify-content-center py-4">

            <Typography variant="h3" color="inherit">
              Share Your Feedback
            </Typography>
          </Toolbar>
        </AppBar>
)}
    >
      <div className="orderChat_detail pt-5">
        <Paper className="order_detial-card d-flex flex-column w-75" elevation={4}>
          <Typography variant="h5" color="primary" align="center">
            {text}
          </Typography>
          <AddFeedbackForm closeAddFeedback={handleClose} orderID={order._id} />
        </Paper>
      </div>
    </DialogBase>
  );
};

export default Index;
