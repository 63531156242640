import queryString from 'query-string';
// import { matchPath } from 'react-router-dom';
// import urljoin from 'url-join';

export function generatePreviousUrl(newPathName, currentLocation) {
  const { pathname, search = '', hash = '' } = currentLocation;
  return `${newPathName}?previous=${encodeURIComponent(`${pathname}${search}${hash}`)}`;
}

const queryStringOptions = {
  arrayFormat: 'comma',
  parseNumbers: true,
  parseBooleans: true,
};

export function stringify(params, options = {}) {
  return queryString.stringify(params, { ...queryStringOptions, ...options });
}

export function parse(search, options = {}) {
  return queryString.parse(search, { ...queryStringOptions, ...options });
}
