/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
import React from 'react';
import { Typography } from '@material-ui/core';

const Index = () => (
  <div className="mx-sm-2 d-flex flex-column">
    <Typography variant="h4" color="textSecondary">
      PACKR PRIVACY NOTICE
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      Your trust matters to us. That is why we protect your information and use it responsibly, while
      continuing to deliver the excellent services you expect from Packr and its operating groups,
      subsidiaries and divisions (hereafter “Packr”). At Packr, we are committed to protecting your privacy
      and the security of information that can directly or indirectly be used to identify a natural person
      (hereafter “Personal Data”). Packr has created this privacy notice (hereafter “Privacy Notice”) to
      explain how Packr collects and uses Personal Data.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      When this Privacy Notice mentions “Packr”, “we”, “us”, or “our”, Packr is referring to the Packr (Pvt.)
      Limited that is deciding on the purposes and means of the processing of your Personal Data under this
      Privacy Notice.
    </Typography>
    <Typography variant="h4" color="textSecondary">
      Overview
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      This Privacy Notice answers the following questions:
    </Typography>
    <Typography variant="body2" className="align-left" align="left" color="textPrimary">
      1.  Does this Privacy Notice apply to you?
    </Typography>
    <Typography variant="body2" className="align-left" align="left" color="textPrimary">
      2.  What Personal Data does Packr collect?
    </Typography>
    <Typography variant="body2" className="align-left" align="left" color="textPrimary">
      3.  How does Packr collect Personal Data?
    </Typography>
    <Typography variant="body2" className="align-left" align="left" color="textPrimary">
      4.  Does Packr use cookies?
    </Typography>
    <Typography variant="body2" className="align-left" align="left" color="textPrimary">
      5.  Who has access to your Personal Data?
    </Typography>
    <Typography variant="body2" className="align-left" align="left" color="textPrimary">
      6.  How long will Packr process your Personal Data?
    </Typography>
    <Typography variant="body2" className="align-left" align="left" color="textPrimary">
      7.  What measures does Packr take to protect your Personal Data?
    </Typography>
    <Typography variant="body2" className="align-left" align="left" color="textPrimary">
      8.  Where does Packr store or transfer your Personal Data?
    </Typography>
    <Typography variant="body2" className="align-left" align="left" color="textPrimary">
      9.  Will there be updates to this Privacy Notice?
    </Typography>
    <Typography variant="h4" className="my-3 align-left" color="textSecondary">
      1.  Does this Privacy Notice apply to you?
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      This Privacy Notice applies to you if you are a user of Packr, a recipient of a package delivered through
      Packr, a supplier of a package delivered through Packr, or if you contact Packr, for instance, by visiting
      www.packrit.com including any (sub)pages and mobile apps (hereafter “Websites”), using social media or if
      you receive emails from Packr.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      Our Websites are for a general audience and not aimed at children. In principle, we do not collect Personal
      Data from children under age 18. If you are under the age of 18 and you want to use our services, please rely
      on a parent or guardian to assist you.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      If a child under the age of 18 may have disclosed Personal Data to us, the parent or guardian can contact
      us at www.packrit..com and we will remove Personal Data if required.
    </Typography>
    <Typography variant="h4" className="my-3 align-left" color="textSecondary">
      2.  What Personal Data does Packr collect?
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      In the course of its business activities and providing the services, Packr will need to process Personal
      Data. Without your Personal Data, we will not be able to provide you with the requested services. As a
      rule, the Personal Data that you provide directly or indirectly to Packr when using our services and
      visiting our Websites are:
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      i.  Contact information: This may include your name, address, email address and phone number.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      ii.  Financial information: This may include your bank account number, payment status, and invoices.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      iii. Identification information: This may include your CNIC and passport number.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      iv. Account information: This may include log-in details, including your email address, and other
      information provided through your account.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      v.  User and preference information: This includes – as applicable – shipping amounts, complaints, history
      of transaction and related commercial activities, communication and survey information.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      vi.  Automatically generated information: This will include IP address, unique device or user ID, system
      and browser type, date and time stamps, referring website address, content and pages you accessed on our
      Websites or mobile apps, dates, times and locations actions take place, websites you visit (if you connect
      to our in-store wireless services), click-stream information and device location (if you turn on the feature in the mobile app).
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      In addition to the above categories of Personal Data, depending on your interaction with Packr, we may
      collect other types of information which may or may not contain Personal Data. Such information related
      to shipments and services may include shipment tracking number, shipment routing information, location
      data, status of a shipment, delivery location, packaging type, number of pieces, weight, picture of the
      parcel, and customs information.
    </Typography>
    <Typography variant="h4" className="my-3 align-left" color="textSecondary">
      3.  How does Packr collect Personal Data?
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      Packr collects Personal Data when it is provided by the user or account holder or by a visitor to our
      website. If you are the user or account holder, then we receive your Personal Data directly from you.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      When Packr receives Personal Data indirectly, we rely on the provider of the Personal Data for the
      accuracy of the information and that the provider has the authority to provide that information to
      Packr.
    </Typography>
    <Typography variant="h4" className="my-3 align-left" color="textSecondary">
      4.  Does Packr use cookies?
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      Yes, Packr uses cookies and similar technologies on its Websites. Through these cookies, Packr automatically
      obtains Personal Data as listed above when you visit our Websites.
    </Typography>
    <Typography variant="h4" className="my-3 align-left" color="textSecondary">
      6. Who has access to your Personal Data?
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      Packr does not sell your personal information.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">

      As a rule, however, Packr shares your Personal Data with third parties in the following circumstances:
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      With its affiliates, operating groups, subsidiaries and divisions, or with third parties if such is
      necessary for the purposes as listed above. If appropriate, Packr will require third parties to conduct
      activities in a manner consistent with Packr policies and guidelines in relation to data protection.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      With data processors, i.e., parties processing Personal Data on our behalf. In such cases, these third
      parties only use your Personal Data for the purposes described above and only in accordance with our
      instructions. Packr will only use processors which provide sufficient guarantees to implement appropriate
      technical and organizational measures and ensure the protection of the rights of data subjects.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      With its employees if and to the extent necessary for the performance of their tasks. In such a case,
      access will be granted only if and to the extent necessary for the purposes described above and only if
      the employee is bound by confidentiality.
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      If and when required to do so by law, court order, or other legal process, for example, with law enforcement
      agencies or other governmental agencies, to establish or exercise our legal rights or in connection with
      a corporate transaction, such as a divesture, merger, consolidation, or asset sale, or in the unlikely
      event of bankruptcy.
    </Typography>
    <Typography variant="h4" className="my-3 align-left" color="textSecondary">
      7.  How long will Packr process your Personal Data?
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      We will retain your Personal Data no longer than necessary for the purpose(s) for which we process your
      Personal Data. After the retention period we will delete or anonymize your Personal Data, unless we need
      to retain certain of your Personal Data for another purpose. We will only do so if we have a legal ground
      to retain your Personal Data. We will also ensure that Personal Data are only accessible for that other
      purpose.
    </Typography>
    <Typography variant="h4" className="my-3 align-left" color="textSecondary">
      8.  What measures does Packr take to protect your Personal Data?
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      We use variety of security measures such as encryption and authentication tools to protect your information.
      We use secured servers when you request for service via the Website. However we cannot guarantee 100% security
      or confidentiality of the information that you provide to us.
    </Typography>
    <Typography variant="h4" className="my-3 align-left" color="textSecondary">
      9. Will there be updates to this Privacy Notice?
    </Typography>
    <Typography variant="body2" className="my-3 align-left" align="left" color="textPrimary">
      We reserve the right to update, amend or change our Privacy Notice at any time therefore you should check
      this Privacy Notice periodically. Your continued use of Packr after we post any modifications to the Policy
      will constitute your acknowledgment of the modifications and your consent to abide and be bound by the
      modified Policy. If we make any material changes to this Privacy Notice, we will notify you either
      through the email address you have provided us, or by placing a prominent notice on our website.
    </Typography>

  </div>

);

export default Index;
