/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import {
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import useRequest from 'hooks/useRequest';
import useOrder from 'hooks/order/useOrder';

import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton } from 'components/buttons/buttons';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function AddFeedback(props) {
  const { closeAddFeedback, orderID } = props;
  const classes = useStyles();
  const [rating, setRating] = useState(0);
  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  const validationSchema = yup.object({
    description: yup
      .string('Enter your Feedback')
      .min(5, 'Atleast 5 characters are required')
      .required('Feedback is required'),

  });
  const [,,,,,,,,, addFeedback] = useOrder();

  const {
    handleSubmit, values, handleChange, touched, errors, isSubmitting,
  } = useFormik({
    initialValues: {
      description: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (valz, actions) => {
      const {
        validate, resetForm, setFieldError, setSubmitting,
      } = actions;

      if (validate) validate(valz);

      const {
        description,
      } = valz;

      addFeedback({
        orderID,
        description,
        rate: rating,

      }).then(() => {
        resetForm();
        closeAddFeedback();
      })
        .catch(({ message }) => {
          setSubmitting(false);
          setFieldError(message.includes('Password') ? 'password' : 'mobile', message);
        });
    },
  });

  return (
    // eslint-disable-next-line react/jsx-no-duplicate-props
    <form className="p-3" className={classes.root} noValidate autoComplete="off" className="align-self-center d-flex flex-column justify-content-around w-100" onSubmit={handleSubmit}>
      <Rating name="read-only" size="large" onChange={handleRatingChange} value={rating} className="m-3 align-self-center" />
      <TextField
        id="description"
        label="Description"
        multiline
        rows={7}
        defaultValue="="
        variant="outlined"
        value={values.description}
        onChange={handleChange}
        error={touched.description && Boolean(errors.description)}
        helperText={touched.description && errors.description}
        fullWidth
      />
      <div align="center">
        <SecondaryButton
          className="mt-5 py-3"
          endIcon={<DoubleArrowOutlinedIcon />}
          type="submit"
          disabled={isSubmitting}
        >
          Submit
        </SecondaryButton>
      </div>

    </form>
  );
}

export default AddFeedback;
