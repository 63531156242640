/* eslint-disable no-unused-vars */
import React from 'react';
// import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Grid, Typography } from '@material-ui/core';
import Mobile from 'assets/mobile.png';

const Third = () => (
  <div className="pt-2 pt-md-5">
    <Grid container>
      <Grid item xs={12} sm={4}>
        <Typography
          variant="button"
          display="block"
          className="text-uppercase font-weight-bold"
          color="textPrimar"
          gutterBottom
        >
          Our App
        </Typography>

        <Typography variant="h2" align="left" color="inherit" gutterBottom>
          How to easily send and receive parcel
        </Typography>

        <List className="w-100">
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className="bg-gradient-primary">
                1
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5',
              }}
              primary="Join the PACKR App"
              secondary="Get started with PACKR App Register link"
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar className="bg-gradient-primary">
                2
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5',
              }}
              primary="Find a Traveller / Orderer"
              secondary="Open the app and find a traveller/orderer to send/receive your parcel"
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar classes="alignItemsFlexStart">
              <Avatar className="bg-gradient-primary">
                3
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5',
              }}
              primary="Easily keep track of your parcel"
              secondary="You can track your parcel and their progress right from the app"
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar classes="alignItemsFlexStart">
              <Avatar className="bg-gradient-primary">
                4
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5',
              }}
              primary="Anything Anywhere"
              secondary="Order anything for any part of the world."
            />
          </ListItem>
        </List>

      </Grid>
      <Grid item xs={12} sm={8} container justify="center">
        <img src={Mobile} alt="dashborad" className="landing-page-hero_imgmobile" />
      </Grid>
    </Grid>

  </div>

);

export default Third;
