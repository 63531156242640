/* eslint-disable react/react-in-jsx-scope */
import { useCallback, useState } from 'react';
import Snakebar from 'components/snakebar';

export default function useAlert() {
  const [displayAlert, setdisplayAlert] = useState(null);
  const errorAlert = useCallback((message) => (
    <Snakebar
      message={message}
      severity="error"
    />
  ), []);
  const infoAlert = useCallback((message) => {
    <Snakebar
      open
      message={message}
      severity="info"
    />;
  }, []);
  const successAlert = useCallback((message) => (
    setdisplayAlert(<Snakebar
      open
      message={message}
      severity="success"
    />)
  ), []);
  const warningAlert = useCallback((message) => {
    <Snakebar
      message={message}
      severity="warning"
    />;
  }, []);
  const successpAlert = useCallback((message) => (
    <Snakebar
      open
      message={message}
      severity="success"
    />
  ), []);

  return [
    errorAlert, infoAlert, successAlert, warningAlert, displayAlert, successpAlert,
  ];
}
