/* eslint-disable max-len */
/* eslint-disable consistent-return */
import { useCallback } from 'react';
// import useAlert from 'hooks/useAlert';
import { useDispatch, useSelector } from 'react-redux';
import orderSelector from 'state/selectors/order';
import {
  newOrder,
  getMyOrders,
  searchOrderByTrip,
  placeOrder,
  getOrderById,
  updateOrderStatus,
  acceptedOrdersByTripId,
  verifyCode,
  feedback,
  feedbackStatus,
} from 'api';
import {
  setOrder, addOrder, updateOrder,
} from 'state/slices/order';
import { setOrderByTrip } from 'state/slices/orders';
import { setAcceptedOrder, updateAcceptedOrder, deleteAcceptedOrder } from 'state/slices/acceptedOrder';
import { setRequestedOrder } from 'state/slices/requestedOrder';
import { setOrderHistory } from 'state/slices/orderHistory';
// import orderSelector from 'state/selectors/order';
import useRequest from '../useRequest';

export default function useOrder() {
  const { doRequest, errors } = useRequest({ url: null });
  const dispatch = useDispatch();
  const { data: orders } = useSelector(orderSelector);

  const add = useCallback(async (values) => {
    try {
      const res = await doRequest(newOrder(), { ...values }, 'post', true)
        .catch((err) => console.log(err.message));
      dispatch(addOrder(res.data));
      return res;
    } catch (err) {
      console.log(err);
    }
  }, [doRequest]);

  const get = useCallback(async (page, isHistory, limit = '30') => {
    const res = await doRequest(getMyOrders(page, isHistory, limit), {}, 'get', true)
      // .then(({ data }) => dispatch(setOrder(data)))
      .catch((err) => console.log(err.stack));
    if (isHistory === 0) dispatch(setOrder(res));
    if (isHistory === 1) dispatch(setOrderHistory(res));
    return res;
  }, [doRequest]);

  const getOrdersByTrip = useCallback(async (page, destination, arrival, travelDate, tripId, ordererName = null) => {
    const res = await doRequest(searchOrderByTrip(page, destination, arrival, travelDate, tripId, ordererName), {}, 'get', true)
      .catch((err) => console.log(err.stack));
    dispatch(setOrderByTrip(res));
    return res;
  }, [doRequest]);

  const addplaceOrder = useCallback(async (values) => {
    try {
      const res = await doRequest(placeOrder(), { ...values }, 'post', true)
        // .then(({ data }) => dispatch(updateOrder(data)))
        .catch((err) => console.log(err.message));
      dispatch(updateOrder(res.data));
      return res;
    } catch (err) {
      console.log(err);
    }
  }, [doRequest]);

  const getOrder = useCallback(async (id) => {
    const res = await doRequest(getOrderById(id), {}, 'get', true)
      .then(({ data }) => dispatch(setRequestedOrder(data)))
      .catch((err) => console.log(err.stack));
    return res;
  }, [doRequest]);

  const updateOrderStatusById = useCallback(async (values) => {
    try {
      const res = await doRequest(updateOrderStatus(values.orderID), { ...values }, 'put', true);
      // .catch((err) => console.log(err.message));
      if (values.user === 'traveller' && res.data.status.length !== 0) dispatch(updateAcceptedOrder(res.data));
      // if (values.user === 'traveller' && res.data.status === 4) dispatch(updateAcceptedOrder(res.data));
      if (values.user === 'traveller' && res.data.status === null) dispatch(deleteAcceptedOrder(res.data));
      return res;
    } catch (err) {
      // return err;
      throw new Error(err.message);
    }
  }, [doRequest]);

  const getAcceptedOrdersByTripId = useCallback(async (page, tripId, isHistory) => {
    try {
      const res = await doRequest(acceptedOrdersByTripId(page, tripId, isHistory), {}, 'get', true)
      // .then(({ data }) => dispatch(setOrder(data)))
        .catch((err) => console.log(err.stack));
      dispatch(setAcceptedOrder(res.data));
      return res;
    } catch (err) {
      console.log(err);
    }
  }, [doRequest]);

  const verifyOrderCode = useCallback(async (values) => {
    try {
      const res = await doRequest(verifyCode(values.orderID), { ...values }, 'put', true);
      //  .catch((err) => console.log(err));
      if (res.data.length !== 0) {
        if (res.data.status[res.data.status.length - 1].statusBy === 'traveler') dispatch(updateAcceptedOrder(res.data));
        if (res.data.status[res.data.status.length - 1].statusBy === 'orderUser') dispatch(updateOrder(res.data));
      }
      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }, [doRequest]);

  const addFeedback = useCallback(async (values) => {
    try {
      const res = await doRequest(feedback(values.orderID), { ...values }, 'put', true);
      if (res.data.length !== 0 && res.data.travellerFeedback !== null) dispatch(updateOrder(res.data));
      if (res.data.length !== 0 && res.data.orderUserFeedback !== null) dispatch(updateAcceptedOrder(res.data));
      return res;
    } catch (err) {
      console.log(err);
    }
  }, [doRequest]);

  const getFeedbackStatus = useCallback(async () => {
    const res = await doRequest(feedbackStatus(), {}, 'get', true)
      // .then(({ data }) => dispatch(setOrder(data)))
      .catch((err) => console.log(err.stack));
    return res;
  }, [doRequest]);

  const getOrderFromStore = useCallback(async (id) => {
    console.log(id);
    const sOrder = orders.filter(({ _id: orderId }) => orderId === id);
    console.log('🚀 ~ file: useOrder.js ~ line 141 ~ getOrderFromStore ~ sOrder', sOrder);
    if (Array.isArray(sOrder) && sOrder.length !== 0) return sOrder[0];

    return null;
  }, []);

  return [
    add,
    errors,
    get,
    getOrdersByTrip,
    addplaceOrder,
    getOrder,
    updateOrderStatusById,
    getAcceptedOrdersByTripId,
    verifyOrderCode,
    addFeedback,
    getFeedbackStatus,
    getOrderFromStore,
  ];
}
