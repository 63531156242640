/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import useAlert from 'hooks/useAlert';
import {
  TextField, InputAdornment, Grid, Typography, Select, MenuItem, InputLabel, FormControl,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useOrder from 'hooks/order/useOrder';
import { SecondaryButton } from 'components/buttons/buttons';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';
import useRedirect from 'hooks/useRedirect';
import 'views/trip/index.scss';

function AddPlaceOrder(val) {
  const { tripId, orderId } = val.data;
  const [currency, setCurrency] = React.useState('USD');
  const [,, successAlert, , displayAlert] = useAlert();
  const [isDisplaySuccess, setDisplaySuccess] = useState(false);

  const handleChangeCurrency = (event) => {
    setCurrency(event.target.value);
  };
  const validationSchema = yup.object({
    // currency: yup.string('Select currency').required('currency is required'),
    orderValue: yup.string('Enter order value').required('Please enter order value.'),

  });
  const [,,,, addplaceOrder] = useOrder();
  const [redirectTo] = useRedirect();
  const {
    handleSubmit, values, handleChange, touched, errors, isSubmitting,
  } = useFormik({
    initialValues: {
      currency: '',
      weight: '',
      orderValue: '',
      serviceCharges: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (valz, {
      validate, resetForm, setFieldError, setSubmitting,
    }) => {
      if (validate) validate(valz);
      const {
        weight, orderValue, serviceCharges,
      } = valz;
      addplaceOrder({
        currency,
        weight,
        serviceCharges,
        orderValue,
        orderID: orderId,
        tripID: tripId,
      }).then(({ success, message }) => {
        if (success === true) {
          successAlert(message);
          setDisplaySuccess(true);
          setTimeout(() => {
            resetForm();
            val.handleClose();
            redirectTo('myOrders');
            setDisplaySuccess(false);
          }, 2000);
        }
      })
        .catch(({ message }) => {
          setSubmitting(false);
          setFieldError(message);
        });
    },
  });

  return (
  // eslint-disable-next-line react/jsx-no-duplicate-props
    <form className="pt-2 trip_form" noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid container justify="center" spacing={1} direction="row">
        <Grid item xs={12} md={11}>
          <Typography variant="h5" className="text-capitalize" display="block" color="primary" gutterBottom align="center">
            Payment Terms
          </Typography>

        </Grid>
        {isDisplaySuccess === true && displayAlert}
        <Grid item xs={12} md={11}>
          <Grid container justify="space-between" spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container justify="space-between" direction="column">
                <FormControl className="mt-3 mx-3 align-self-center" style={{ width: '90%' }}>
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    style={{
                      top: '-8px',
                      left: '11px',
                      zIndex: 1000000000,
                      backgroundColor: 'white',
                      padding: '2px 4px',
                    }}
                  >
                    Currency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="currency"
                    value={currency}
                   // className="mt-3 mx-3 align-self-center"
                    onChange={handleChangeCurrency}
                    autoComplete="currency"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                      endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                    }}
                    variant="outlined"
                    error={touched.currency && Boolean(errors.currency)}
                    helperText={touched.currency && errors.currency}
                   // style={{ width: '90%' }}
                  >
                    <MenuItem value="USD">USD - US dollar</MenuItem>
                    <MenuItem value="EUR">EUR - European Euro</MenuItem>
                    <MenuItem value="JPY">JPY - Japanese yen</MenuItem>
                    <MenuItem value="GBP">GBP - Pound sterling</MenuItem>
                    <MenuItem value="AUD">AUD - Australian dollar</MenuItem>
                    <MenuItem value="CAD">CAD - Canadian dollar</MenuItem>
                    <MenuItem value="CNY">CNY - Chinese Yuan</MenuItem>
                    <MenuItem value="PKR">PKR - Pakistani Rupee</MenuItem>
                  </Select>

                </FormControl>
                <TextField
                  id="weight"
                  label="Weight"
                  type="text"
                  autoComplete="weight"
                  // className="mt-3"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                    endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                  }}
                  variant="outlined"
                  value={values.weight}
                  onChange={handleChange}
                  error={touched.weight && Boolean(errors.weight)}
                  helperText={touched.weight && errors.weight}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justify="space-between" direction="column">
                <TextField
                  id="orderValue"
                  label="Order Value"
                  type="text"
                  // className="mt-3"
                  autoComplete="orderValue"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                    endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                  }}
                  variant="outlined"
                  value={values.orderValue}
                  onChange={handleChange}
                  error={touched.orderValue && Boolean(errors.orderValue)}
                  helperText={touched.orderValue && errors.orderValue}
                />
                <TextField
                  id="serviceCharges"
                  label="Service Charges"
                  type="text"
                  className="mt-0"
                  autoComplete="serviceCharges"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                    endAdornment: <InputAdornment position="end"><></></InputAdornment>,
                  }}
                  variant="outlined"
                  value={values.serviceCharges}
                  onChange={handleChange}
                  error={touched.serviceCharges && Boolean(errors.serviceCharges)}
                  helperText={touched.serviceCharges && errors.serviceCharges}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} md={12} />
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          <SecondaryButton
            className="mt-3 py-2"
            endIcon={<DoubleArrowOutlinedIcon />}
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            Submit
          </SecondaryButton>
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={12}>
          <Typography variant="caption" component="small" className="text-capitalize" align="center" color="textPrimary" display="block" gutterBottom>
            *As agreed between sender and traveller
          </Typography>
        </Grid>
        <Grid item xs={0} md={4} />

      </Grid>
    </form>
  );
}

export default AddPlaceOrder;
