/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
// import { set } from 'dot-prop-immutable';
// My Order
export const initialState = { data: [] };

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrder(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearOrder(state) {
      return { ...state, order: null };
    },
    addOrder(state, action) {
      const { payload } = action;

      return { ...state, data: [...state.data, payload] };
    },
    updateOrder(state, action) {
      const { payload } = action;

      return {
        ...state,
        data: state.data.map((item) => {
        // Replace the item
          if (item._id === payload._id) {
            return payload;
          }

          // Leave every other item unchanged
          return item;
        }),
      };
    },

  },
});

export const {
  setOrder, clearOrder, addOrder, updateOrder,
} = slice.actions;

export default slice.reducer;
