import React from 'react';
// import useQueryString from 'hooks/useQueryString';
// import Button from '@material-ui/core/Button';
import useRedirect from 'hooks/useRedirect';
import {
  IconButton,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

const MenuButton = ({ ...rest }) => {
  // const [, setParams] = useQueryString();
  const [redirectTo] = useRedirect();
  const handleClick = async () => {
    redirectTo('dashboard');
    // setParams({ changePassword: true });
  };
  return (
    // <Button {...rest} onClick={handleClick}>Change Password</Button>
    <IconButton
      edge="start"
      aria-label="menu"
      {...rest}
      onClick={handleClick}
    >
      <FilterListIcon />
    </IconButton>
  );
};

export default MenuButton;
