import React from 'react';
import useAlert from 'hooks/useAlert';
import {
  Container, AppBar, Toolbar, // Box, , Avatar,
} from '@material-ui/core';
import NotificationBtn from 'components/buttons/notificationBtn';
import Drawer from 'components/sideMenu/drawer';
import { ReactComponent as Logo } from 'assets/logo-small-white.svg';
// import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import './index.scss';

const Index = ({ children }) => {
  const [,,,, displayAlert] = useAlert();

  return (
    <>
      <AppBar position="static" className="p-0 m-0 bg-gradient-secondary" color="secondary">
        <Toolbar variant="dense" className="d-flex justify-content-between py-1">

          <Drawer />

          <Logo className="orange-layout m-0 p-0" />

          <NotificationBtn color="inherit" className="mr-1" />
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth={false} className="main-container--orange px-0 ">

        {children}
        {displayAlert}
      </Container>
    </>
  );
};

export default Index;
