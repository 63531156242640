/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import useSysSocket from 'hooks/useSysSocket';
import useGetCount from 'hooks/notification/getCount';
import { useDispatch, useSelector } from 'react-redux';
import { setSeenCount } from 'state/slices/notification';
import notificationSelector from 'state/selectors/notification';

export default function useShowCount() {
  const { seenCount } = useSelector(notificationSelector);
  const dispatch = useDispatch();
  const { notificationCount: syscount } = useSysSocket();
  console.log('🚀 ~ file: showCount.js ~ line 13 ~ useShowCount ~ syscount', syscount);
  const count = useGetCount();
  console.log('🚀 ~ file: showCount.js ~ line 14 ~ useShowCount ~ count', count);

  useEffect(() => {
    const scount = syscount || 0;
    // if (seenCount === 0 || seenCount < count || seenCount < syscount) {
    if (syscount !== null) dispatch(setSeenCount(scount));
    // }
    return () => {
      // setfinalCount(null);
    };
  }, [syscount]);

  return seenCount;
}
