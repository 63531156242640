import React from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
import banner1 from 'assets/slider/banner1.png';
import banner2 from 'assets/slider/banner2.png';
// import banner3 from 'assets/slider/image3.jpg';
// import banner4 from 'assets/slider/image4.jpg';

import Carousel from 'react-material-ui-carousel';
import { Paper } from '@material-ui/core';

export default function Slider() {
  const items = [
    {
      name: 'Random Name #1',
      description: 'Probably the most random thing you have ever seen!',
      image: banner1,
    },
    {
      name: 'Random Name #2',
      description: 'Hello World!',
      image: banner2,
    },
  ];

  return (
    <Carousel
      className="p-0 m-0"
      NavButton={() => (
        <p className="d-none" />
      )}
      activeIndicatorIconButtonProps={{
        style: {
          color: '#f50c0c', // 2
        },
      }}
      indicatorContainerProps={{
        style: {
          marginTop: '-50px', // 5
        },
      }}
    >
      {
                items.map((item) => <Item key={Math.random()} item={item} />)
            }
    </Carousel>
  );
}

function Item({ item }) {
  return (
    <Paper className="dashboard__slider p-0 m-0" style={{ 'background-image': `url(${item.image})`, 'background-size': 'contain !important', 'background-position': 'left top' }} elevation={0} square>
      {/* <p className="p-0 m-0">{item.name}</p>
      <p className="p-0 m-0">{item.description}</p>

      <Button className="CheckButton">
        Check it out!
      </Button> */}
    </Paper>
  );
}
