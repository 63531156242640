/* eslint-disable consistent-return */
import { useCallback } from 'react';
// import useAlert from 'hooks/useAlert';
import { useDispatch, useSelector } from 'react-redux';
import {
  signin, forgotPassword, recoverAccount, signup, changePassword, getUserbyId,
} from 'api';
import {
  setUser, setDetail, updateDetail,
} from 'state/slices/auth';
import { auth as authSelector } from 'state/selectors/auth';
import useRequest from './useRequest';

export default function useAuth() {
  const { doRequest, errors } = useRequest({ url: null });
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);

  const signIn = useCallback(async (values) => {
    const res = await doRequest(signin(), { grant_type: 'password', ...values }, 'post')
      .then(({ data }) => dispatch(setUser({ data, isOnline: true })));

    return res;
  }, [dispatch, doRequest]);

  const signinStore = useCallback(
    (data) => dispatch(setUser({ data, isOnline: true })), [dispatch, doRequest],
  );

  const forgotPass = useCallback(async (values) => {
    const res = await doRequest(forgotPassword(), { ...values }, 'post');

    return res;
  }, [doRequest]);

  const signUp = useCallback(async (values) => {
    const res = await doRequest(signup(), { ...values }, 'post').then(({ data }) => dispatch(setUser({ ...data, isOnline: true })));

    return res;
  }, [dispatch, doRequest]);

  const { isOnline } = auth; // useCallback(() => auth.isOnline, []);
  const isAuthLoaded = auth.user === null || Boolean(auth.user.data) || false;
  const resetPassword = useCallback(async (values) => {
    const res = await doRequest(recoverAccount(), { ...values }, 'post');

    return res;
  }, [doRequest]);

  const changePass = useCallback(async (values) => {
    try {
      const res = await doRequest(changePassword(), { ...values }, 'post', true);
      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }, [doRequest]);

  const userToken = (auth.user && auth.user.data) ? auth.user.data.access_token : '';

  const isMine = (uid) => {
    if (auth.user) {
      // console.log('yes');
      // console.log(' ~ uid', uid);
      // console.log('auth.user.data.userID', auth.user.data.userID);
      // console.log('🚀 ~ auth.user.data.userID === uid', auth.user.data.userID === uid);
      return auth.user.data.userID === uid;
    }

    return false;
  };

  const getUserDetail = useCallback(async () => {
    try {
      if (Boolean(auth.user) && auth.user.detail) return auth.user.detail;
      // console.log('------------->', auth.user.detail);
      if (auth.user) {
        const res = await doRequest(getUserbyId(auth.user.data.userID))
          .then(({ data }) => dispatch(setDetail(data)));

        return res;
      }
      return false;
    } catch (err) {
      console.log(err);
    }
  }, [auth.user, dispatch, doRequest]);

  const updateUserDetail = useCallback(
    async (values) => {
      // if (auth.user.detail) return auth.detail;

      const res = await doRequest(getUserbyId(auth.user.data.userID), { ...values }, 'put', true)
        .then(({ message }) => {
          dispatch(updateDetail(values));
          return message;
        })
        .catch((err) => console.log(err));

      return res;
    }, [dispatch, doRequest],
  );
  const user = auth.user ? auth.user.detail : '';
  return {
    signIn,
    signUp,
    isOnline,
    resetPassword,
    errors,
    forgotPass,
    changePass,
    userToken,
    isMine,
    isAuthLoaded,
    getUserDetail,
    updateUserDetail,
    user,
    signinStore,
  };
}
