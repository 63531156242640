import { createSlice } from '@reduxjs/toolkit';

export const initialState = { tripChatStatus: null };

const slice = createSlice({
  name: 'tripChatStatus',
  initialState,
  reducers: {
    setTripChatStatus(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearTripChatStatus(state) {
      return { ...state, tripChatStatus: null };
    },
  },
});

export const { setTripChatStatus, clearTripChatStatus } = slice.actions;

export default slice.reducer;