/* eslint-disable no-unused-vars */
import { useCallback, useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { useHistory, useLocation } from 'react-router-dom';
import { initialPages, authPages } from 'routes/url';

export default function useVerifyUser() {
  const history = useHistory();
  const location = useLocation();
  const { isOnline, isAuthLoaded } = useAuth();
  const verifyAuthUser = useCallback(() => {
    if (isAuthLoaded
        && !isOnline
        && !Object.values(initialPages)
          .includes(location.pathname)) history.push(initialPages.signIn);
    else if (isAuthLoaded
        && isOnline
        && Object.values(initialPages)
          .includes(location.pathname)) history.push(authPages.dashboard);

    return location;
  });

  useEffect(() => {
    if (isAuthLoaded) verifyAuthUser();

    return () => false;
  }, [isAuthLoaded, isOnline, verifyAuthUser]);

  return [verifyAuthUser];
}
