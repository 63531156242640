import { useCallback } from 'react';
// import useAlert from 'hooks/useAlert';
import { useDispatch, useSelector } from 'react-redux';
import tripSelector from 'state/selectors/trip';
import {
  addTrip, getMyTrip, getTripById, searchTripByOrderId,
} from 'api';
import { setTrip, addTrip as addTripStore } from 'state/slices/trip';
import { setTripByOrder } from 'state/slices/trips';
import { setTripHistory } from 'state/slices/tripHistory';
// import { auth } from 'state/selectors/auth';
import useRequest from '../useRequest';

export default function useTrip() {
  const { doRequest, errors } = useRequest({ url: null });
  const dispatch = useDispatch();
  const { data: trips } = useSelector(tripSelector);

  const addNewTrip = useCallback(async (values) => {
    try {
      const res = await doRequest(addTrip(), { ...values }, 'post', true);
      //  .catch((err) => console.log(err));
      dispatch(addTripStore(res.data));
      return res;
    } catch (err) {
      throw new Error(err.message);
    }
  }, [doRequest]);

  const getMyTrips = useCallback(async (page, isHistory, limit = '30') => {
    const res = await doRequest(getMyTrip(page, isHistory, limit), {}, 'get', true)
      // .then(({ data }) => dispatch(setOrder(data)))
      .catch((err) => console.log(err.stack));
    if (isHistory === 0) dispatch(setTrip(res));
    if (isHistory === 1) dispatch(setTripHistory(res));
    return res;
  }, [doRequest]);

  const getTrip = useCallback(async (id) => {
    const res = await doRequest(getTripById(id), {}, 'get', true)
      // .then(({ data }) => dispatch(setOrder(data)))
      .catch((err) => console.log(err.stack));
    // dispatch(setTrip(res));
    return res;
  }, [doRequest]);

  const getTripsByOrder = useCallback(async (
    page, destination, arrival, fromDate, toDate, orderId, travellerName = null,
  ) => {
    const res = await doRequest(searchTripByOrderId(page, destination, arrival, fromDate, toDate, orderId, travellerName), {}, 'get', true)
      .catch((err) => console.log(err.stack));
    dispatch(setTripByOrder(res));
    return res;
  }, [doRequest]);

  const getTripFromStore = useCallback(async (id) => {
    console.log(id);
    const strip = trips.filter(({ _id: tripId }) => tripId === id);
    console.log('🚀 ~ file: useOrder.js ~ line 141 ~ getOrderFromStore ~ sOrder', strip);
    if (Array.isArray(strip) && strip.length !== 0) return strip[0];

    return null;
  }, []);

  return [addNewTrip, errors, getMyTrips, getTrip, getTripsByOrder, getTripFromStore];
}
