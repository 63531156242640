import { createSlice } from '@reduxjs/toolkit';

export const initialState = { user: null };

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      const { payload } = action;
      return { ...state, isOnline: true, user: payload };
    },
    clearUser(state) {
      return { ...state, isOnline: false, user: null };
    },
    setDetail(state, action) {
      const { payload } = action;
      return { ...state, user: { ...state.user, detail: payload } };
    },
    updateDetail(state, action) {
      const { payload } = action;
      return {
        ...state,
        user: {
          ...state.user,
          detail: { ...state.user.detail, ...payload },
        },
      };
    },
  },
});

export const {
  setUser, clearUser, setDetail, updateDetail,
} = slice.actions;

export default slice.reducer;
