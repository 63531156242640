import React from 'react';
// eslint-disable-next-line import/extensions
import {
  Typography, Link,
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { initialPages } from 'routes/url';
import { SimpleLayout } from 'components/layouts';
import { GoogleButton, LogoButton } from 'components/buttons';
import { ReactComponent as Logo } from 'assets/logo-big-dark.svg';
import './index.scss';

const Landing = () => {
  const history = useHistory();
  return (
    <SimpleLayout>
      <Typography variant="h1" color="textSecondary" align="center" className="landing-page ">
        Welcome to
        {' '}
        <span>PACKR</span>
      </Typography>

      <Logo alt="Packrit" className="landing-page--avatar" />

      <GoogleButton
        className="landing-page--button p-3 font-weight-bold"
      >
        Sign Up with Google
      </GoogleButton>
      {/* <FacebookButton
        className="landing-page--button p-3 font-weight-bold"
      >
        Sign Up with Facebook
      </FacebookButton> */}
      <Typography variant="subtitle1" gutterBottom>
        Or
      </Typography>
      <LogoButton
        className="landing-page--button p-3 font-weight-bold bg-gradient-secondary"
        onClick={() => {
          history.push(initialPages.signUp);
        }}
      >
        Sign Up Manually
      </LogoButton>

      <Typography variant="subtitle2" align="center" className="landing-page ">
        Already have an account?
        {' '}

        <Link component={RouterLink} to={initialPages.signIn} color="secondary">
          Log In
        </Link>
      </Typography>
    </SimpleLayout>
  );
};

export default Landing;
