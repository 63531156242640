/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateTripChat, emptyTripsChat } from 'state/slices/trips';
import { updateOrdersChat, emptyOrdersChat } from 'state/slices/orders';
import { set } from 'dot-prop-immutable';

export default function useStatusUpdate() {
  const dispatch = useDispatch();
  const updateStatus = useCallback((message) => {
    const { type } = message;

    switch (type) {
      case 'Chat Request':
        if (message.receiver === 'orderUser') {
          try {
            dispatch(updateTripChat({
              tripID: message.tripId,
              data: {
                order: { _id: message.orderId },
                status: 1,
                requestUser: 'traveler',
                responseUser: 'orderUser',
              },
            }));
          } catch (e) {
            console.log(e);
          }
        } else if (message.receiver === 'traveler') {
          try {
            dispatch(updateOrdersChat({
              orderID: message.orderId,
              data: {
                trip: { _id: message.tripId },
                status: 1,
                responseUser: 'traveler',
                requestUser: 'orderUser',
              },
            }));
          } catch (e) {
            console.log(e);
          }
        }

        break;

      case 'Chat Accept':
        if (message.receiver === 'orderUser') {
          try {
            dispatch(updateOrdersChat({
              orderID: message.orderId,
              data: {
                trip: { _id: message.tripId },
                status: 2,
                responseUser: 'traveler',
                requestUser: 'orderUser',
              },
            }));
          } catch (e) {
            console.log(e);
          }
        } else if (message.receiver === 'traveler') {
          try {
            dispatch(updateTripChat({
              tripID: message.tripId,
              data: {
                order: { _id: message.orderId },
                status: 2,
                requestUser: 'traveler',
                responseUser: 'orderUser',
              },
            }));
          } catch (e) {
            console.log(e);
          }
        }
        break;

      case 'Chat Reject':
        if (message.receiver === 'orderUser') {
          try {
            dispatch(emptyOrdersChat(message.orderId));
          } catch (e) {
            console.log(e);
          }
        } else if (message.receiver === 'traveler') {
          try {
            dispatch(emptyTripsChat(message.tripId));
          } catch (e) {
            console.log(e);
          }
        }
        break;

      default:
        break;
    }
    return true;
  }, []);

  return updateStatus;
}
