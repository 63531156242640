import React, { useState, useEffect } from 'react';
import { OrangeLayout } from 'components/layouts';
import {
  IconButton, SvgIcon, // Box, , Avatar,
} from '@material-ui/core';
import { ReactComponent as NewOrder } from 'assets/newOrder.svg';
import { ReactComponent as NewTrip } from 'assets/newTrip.svg';
import useOrder from 'hooks/order/useOrder';
import useRedirect from 'hooks/useRedirect';
import NewOrderModal from 'components/modals/newOrder';
import FeedBack from 'components/modals/feedBack';
import Slider from './slider';
import MyOrders from './myOrders';
import MyTrips from './myTrips';
import './index.scss';

const Dashboard = () => {
  // const [, setParams] = useQueryString();
  const [redirectTo] = useRedirect();
  const [,,,,,,,,,, getFeedbackStatus] = useOrder();
  const [addOrder, setaddOrder] = useState(false);
  const [FeedBackModal, setFeedBackModal] = useState(false);
  const [order, setOrder] = useState(false);
  const handleOrder = async () => {
    setaddOrder(true);
  };
  const handleTrip = async () => {
    redirectTo('addTrip');
  };
  useEffect(() => {
    if (!order) {
      getFeedbackStatus().then((res) => {
        if (res && res.data.isFeedBack === true) {
          setFeedBackModal(true);
          setOrder(res.data);
        }
      });
    }
  }, [order]);
  return (
    <OrangeLayout className="dashboard">
      <Slider className="dashboard__banner" />
      <div className="dashboard_buttons">
        <IconButton edge="start" onClick={handleOrder} className="m-0 p-0 w-auto h-auto" color="inherit" aria-label="menu">
          <SvgIcon component={NewOrder} width="180" height="170" className="dashboard_buttons-big" viewBox="70 20 80 160" />
        </IconButton>
        <IconButton edge="start" onClick={handleTrip} className="m-0 p-0 w-auto h-auto" color="inherit" aria-label="menu">
          <SvgIcon component={NewTrip} width="180" height="170" className="dashboard_buttons-big" viewBox="70 20 80 160" />
        </IconButton>
      </div>
      <div className="d-flex justify-content-around justify-content-sm-center flex-column flex-md-row px-sm-5 mt-2 mt-sm-5">
        <MyOrders />
        <MyTrips />
      </div>
      <NewOrderModal open={addOrder} closeit={() => setaddOrder(false)} />
      <FeedBack
        open={FeedBackModal}
        handleClose={() => setFeedBackModal(false)}
        order={order}
        text={order.text}
        userName={order.userName}
      />
    </OrangeLayout>
  );
};

export default Dashboard;
