/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import useRedirect from 'hooks/useRedirect';
import useSignOut from 'hooks/useSignOut';
import {
  Link,
} from '@material-ui/core';

const LogoutButton = ({ className, children }) => {
  const signOut = useSignOut();
  const handleClick = () => signOut();

  return (
    <>
      <Link
        className={className}
        onClick={handleClick}
        color="inherit"
      >
        {children}
      </Link>

    </>
  );
};

export default LogoutButton;
