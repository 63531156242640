import React, { Suspense, lazy } from 'react';
import {
  Switch, Route, useLocation, Redirect,
} from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
// Import Pages
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import LandingPage from 'views/landing';
import HomePage from 'views/home';
import LoginPage from 'views/login';
import ResetPasswordPage from 'views/reset-password';
import SignUpPage from 'views/signup';
import Four04 from 'views/404';
import Dashboard from 'views/dashboard';
import OrderForm from 'views/order/form';
import AddTrip from 'views/trip/addTrip';
import TermConditionPage from 'views/termCondition';
import PrivacyPolicyPage from 'views/privacypolicy';
import Loader from 'components/loader';
// import HistoryOrder from 'views/history/orderDetail';
import useVerifyUser from 'hooks/useVerifyUser';
import { initialPages, authPages } from './url';

const OrderDetail = lazy(() => import('views/order/tabs2'));
const OrderTraveller = lazy(() => import('views/order/traveller'));
const OrderList = lazy(() => import('views/order/list'));
const OrderStatustab = lazy(() => import('views/order/statustab'));

const Historytab = lazy(() => import('views/history/index'));
const HistoryTriptab = lazy(() => import('views/history/triptabs'));
const HistoryOrdertab = lazy(() => import('views/history/ordertabs'));

const TripDetail = lazy(() => import('views/trip/tabs2'));
const TripOrders = lazy(() => import('views/trip/orders'));
const MyTrip = lazy(() => import('views/trip/myTrip'));

const Routes = () => {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [verifyAuthUser] = useVerifyUser();
  // console.log('🚀 ~ file: index.js ~ line 32 ~ Routes ~ verifyAuthUser', verifyAuthUser());
  const pageVariants = {

    initial: {
      opacity: 0,
      // scale: 0.69,
      x: -200,
    },
    in: {
      opacity: 1,
      scale: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      //  scale: 2.01,
      x: 0,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.9,
  };

  return (
    <AnimatePresence>
      <Suspense
        fallback={<Loader />}
      >
        <Switch>
          {/* <Redirect exact from="/" to="/LandingPage" /> */}
          <Route exact path={_.values(initialPages)}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <Route exact path={initialPages.home} component={HomePage} />

                <Route path={initialPages.landing} component={LandingPage} />
                <Route path={initialPages.signIn} component={LoginPage} />
                <Route path={initialPages.resetPassword} component={ResetPasswordPage} />
                <Route path={initialPages.signUp} component={SignUpPage} />
                <Route path={initialPages.termCondition} component={TermConditionPage} />
                <Route path={initialPages.privacyPolicy} component={PrivacyPolicyPage} />

              </motion.div>
            </Switch>
          </Route>

          <Route
            path={_.values(authPages)}
          >
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <Route
                  path={authPages.dashboard}
                  component={Dashboard}
                />

                <Route
                  path={authPages.addOrder}
                  component={OrderForm}
                />

                <Route
                  path={authPages.addOrderSend}
                  component={OrderForm}
                />
                <Route
                  path={authPages.orderDetail}
                  component={OrderDetail}
                />
                <Route
                  path={authPages.orderTraveller}
                  component={OrderTraveller}
                />
                <Route
                  path={authPages.addTrip}
                  component={AddTrip}
                />
                <Route
                  path={authPages.tripDetail}
                  component={TripDetail}
                />
                <Route
                  path={authPages.tripOrders}
                  component={TripOrders}
                />
                <Route
                  path={authPages.myTrip}
                  component={MyTrip}
                />
                <Route
                  path={authPages.myOrders}
                  component={OrderList}
                />
                <Route
                  path={authPages.orderStatus}
                  component={OrderStatustab}
                />
                <Route
                  path={authPages.myHistory}
                  component={Historytab}
                />
                <Route
                  path={authPages.orderHistory}
                  component={HistoryOrdertab}
                />
                <Route
                  path={authPages.tripHistory}
                  component={HistoryTriptab}
                />
              </motion.div>
            </Switch>
          </Route>
          <Route path="/404" component={Four04} />
          <Redirect to="/404" />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
