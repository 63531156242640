import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { stringify, parse } from 'utils/query';

export default function useQueryString(settings) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = parse(search, settings);

  const setParams = useCallback((newQueryParams, fragment = '') => {
    history.push({
      location: pathname,
      search: stringify({ ...params, ...newQueryParams }, settings),
      hash: fragment,
    });
  }, [history, params, pathname, settings]);

  return [params, setParams];
}
