/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Avatar, Grid, Typography } from '@material-ui/core';
import User from 'assets/user.png';
import Review from 'assets/review.png';

const Infobox = ({ name, location }) => (
  <div className="d-flex m-3">
    <Avatar alt="Remy Sharp" src={User} className="avatar" />
    <Typography
      variant="h5"
      display="block"
      color="primary"
      className="p-2"
      gutterBottom
    >
      {name}
      <Typography
        variant="body2"
        display="block"
        color="primary"
        gutterBottom
      >
        {location}
      </Typography>
    </Typography>
  </div>
);

const Reviewcard = ({ data, ...props }) => {
  const { name, location, review } = data;
  return (
    <Paper elevation={7} className="landing-page--review mx-1 mx-sm-3 " {...props}>
      <Grid container style={{ width: '265px' }}>
        <Grid item xs={6}>
          <Infobox name={name} location={location} />
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          <img src={Review} alt="dashborad" className="image p-3" />
        </Grid>
        <Grid item xs={12} className="mx-3">
          <Typography
            variant="subtitle2"
            display="block"
            color="primary"
            gutterBottom

          >
            {review}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Reviewcard;
