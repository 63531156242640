import React from 'react';
import Paper from '@material-ui/core/Paper';
import './index.scss';

const PinkBox = ({ children }) => (
  <Paper elevation={0} className="pink-container mb-3" style={{ display: 'grid' }}>
    {children}
  </Paper>
);

export default PinkBox;
