import React from 'react';
import { TealLayout } from 'components/layouts';
import OrderForm from 'components/forms/order';

const Form = () => (
  <TealLayout title="Place An Order" istopMenu>
    <div className="d-flex justify-content-start flex-column w-md-50 mt-0 ">
      <OrderForm />
    </div>

  </TealLayout>
);

export default Form;
