import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
import useRequest from 'hooks/useRequest';
import { getCountryCallingCode } from 'api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dialCodes from 'utils/dialCode';

// eslint-disable-next-line react/prop-types
const DialCodeDropDown = ({ setFieldValue }) => {
  const uderline = true;
  const [defalutCode, setDefaultCode] = useState(dialCodes[0]);

  const { doRequest, errors } = useRequest({
    url: getCountryCallingCode(),
    onSuccess: (code) => setDefaultCode(dialCodes.find((v) => v.phone === code)),
  });

  useEffect(() => {
    if (!defalutCode.phone) {
      (async () => {
        await doRequest();
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defalutCode) setFieldValue('dialcode', defalutCode);
  }, [defalutCode, setFieldValue]);

  return (
    <>
      {errors}
      <Autocomplete
        id="countryCode"
        value={defalutCode}
        options={dialCodes}
        autoHighlight
        getOptionLabel={(option) => option.phone}
        renderOption={(option) => (
          <div className="w-100">
            {option.phone}
          </div>
        )}
        onChange={(e, value) => {
          setDefaultCode(value);
        }}
        renderInput={(params) => (
          <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            value={defalutCode}
            InputProps={{ ...params.InputProps, disableUnderline: uderline.valueOf }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              ...params.inputProps,
              maxLength: 4,
              // autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </>
  );
};

export default DialCodeDropDown;
