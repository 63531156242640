import React from 'react';
// import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import { WhitePrimaryButton, WhiteOutlineButton } from 'components/buttons/buttons';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { useHistory } from 'react-router-dom';
import { initialPages } from 'routes/url';
// import Link from '@material-ui/core/Link';
// Mobile-Tablet-Monitor-Display.svg
// import { ReactComponent as Dashboard } from 'assets/Mobile-Tablet-Monitor-Display.svg';
import Dashboard from 'assets/Mobile-Tablet-Monitor-Display.png';

const Hero = ({ toApp }) => {
  const history = useHistory();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={6} className="landing-page-hero_topdar">
          <h1 className="landing-page-hero_topdar-h1">
            Anything
          </h1>
          <h1 className="landing-page-hero_topdar--orange">
            Anywhere
          </h1>
          <Typography variant="h5" align="left" className="landing-page-hero_topdar--detail" gutterBottom>
            A platform where people can connect with
            travellers from any part of
            the world going to or coming from a city of your desire to send and receive packages.
          </Typography>
          <div className="d-flex flex-column flex-lg-row mt-1 mt-sm-3 mt-lg-5 pr-md-3">
            <WhiteOutlineButton
              variant="outlined"
              className="text-uppercase landing-page-hero_button mb-3 mb-lg-0"
              endIcon={<ArrowDownwardIcon />}
              onClick={toApp}
            >
              Learn More
            </WhiteOutlineButton>

            <WhitePrimaryButton
              variant="contained"
              endIcon={<CallMadeIcon />}
              className="text-uppercase ml-0 ml-lg-5 landing-page-hero_button"
              onClick={() => {
                history.push(initialPages.landing);
              }}
            >
              Join the space
            </WhitePrimaryButton>

          </div>
        </Grid>
        <Grid item xs={12} sm={6} container justify="center" classname="m-2">
          {/* <Dashboard className="h-50 h-md-75" /> */}
          <img src={Dashboard} alt="dashborad" className="landing-page-hero_img" />
        </Grid>
      </Grid>

      {/* <div className={classes.heroButtons}>
        <Grid container spacing={2} justify="center">
          <Grid item>
            <Button variant="contained" color="primary">
              Main call to action
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary">
              Secondary action
            </Button>
          </Grid>
        </Grid>
      </div> */}

    </div>

  );
};

export default Hero;
