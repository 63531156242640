import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
import { initialPages } from 'routes/url';
import { ReactComponent as Logo } from 'assets/logo-white-small.svg';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    '& .MuiToolbar-gutters': {
      padding: '0px !important',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default function Header({ toAbout, toContact }) {
  const classes = useStyles();
  const history = useHistory();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem> Home </MenuItem>
      <MenuItem color="inherit" onClick={toAbout}>About Us</MenuItem>
      <MenuItem color="inherit" onClick={toContact}>Contact Us</MenuItem>
      <MenuItem
        variant="outlined"
        color="secondary"
        onClick={() => {
          history.push(initialPages.signIn);
        }}
        className="font-weight-bold"
      >
        Login / Register
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" color="transparent" elevation={0} className="landing-page-header">
        <Toolbar>
          <Logo className="landing-page_logo" />

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button color="inherit">Home</Button>
            <Button color="inherit" onClick={toAbout}>About Us</Button>
            <Button color="inherit" onClick={toContact}>Contact Us</Button>
            <Button
              variant="outlined"
              color="secondary"
              className="font-weight-bold"
              onClick={() => {
                history.push(initialPages.signIn);
              }}
            >
              Login / Register
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
