/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import React, { } from 'react';
import {
  Paper, Typography, Link, Grid, Chip,
} from '@material-ui/core';
import useRedirect from 'hooks/useRedirect';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as JourneyLine } from 'assets/journeyLine2.svg';
import { convertDate } from 'utils/convertDate';
import InfoNode from 'components/infoNode';
import './index.scss';

const TripCard = ({ data: trip }) => {
  const [, routeTo] = useRedirect();
  return (
    <Paper className="order_listing-card d-flex flex-column m-2" elevation={2}>

      <Link
        component={RouterLink}
        to={routeTo('tripDetail', { id: trip._id })}
        color="inherit"
        className="order_listing-link"
        underlineNone
      >

        <div className="order_listing-order p-2 d-flex flex-row">

          <InfoNode type="Departure" city={trip.departure} date={convertDate(trip.travelDate)} />
          <JourneyLine className="order_listing-bar mt-2 w-75" />

          <InfoNode type="Arrival" city={trip.arrival} date="" />
        </div>
        <Grid
          item
          xs={12}
          container
          justify="center"
          alignItems="center"
          className="m-0"
        >
          <Chip
            size="small"
            label={`${trip.availableSpace} KG Space Available`}
            color="primary"
            className="align-self-center mb-1"
          />
        </Grid>
      </Link>
    </Paper>

  );
};

export default TripCard;
