/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useQueryString from 'hooks/useQueryString';
import {
  Link,
} from '@material-ui/core';

const ClientFeedbackButton = ({ className, children }) => {
  const [, setParams] = useQueryString();
  const handleClick = () => {
    setParams({ clientfeedback: true });
  };

  return (
    <>
      <Link
        className={className}
        onClick={handleClick}
        color="inherit"
      >
        {children}
      </Link>

    </>
  );
};

export default ClientFeedbackButton;
