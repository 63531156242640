// eslint-disable-next-line import/prefer-default-export
export const getMonth = (monthIndex) => {
  const months = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[monthIndex];
};
