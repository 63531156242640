import React from 'react';
// import useRequest from 'hooks/useRequest';
import { googleLogin } from 'api';
import { ReactComponent as GoogleIcon } from 'assets/googleIcon.svg';
import { WhiteButton } from './buttons';

const GoogleButton = ({ className, children }) => {
  // const { doRequest, errors } = useRequest({
  //   url: googleLogin(),
  //   method: 'post',
  //   onSuccess: false,
  //   onError: false,
  // });
  const handleClick = () => {
    window.location = googleLogin();
  };

  return (
    <>
      <WhiteButton
        variant="contained"
        onClick={handleClick}
        className={className}
        startIcon={<GoogleIcon className="mr-2" />}
      >
        {children}
      </WhiteButton>

    </>
  );
};

export default GoogleButton;
