import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';

const footer = () => {
  function Copyright() {
    return (
      <Typography variant="body2" color="textPrimary" align="center" className="p-3 borderTop">
        {'© Alright Rights Reserved -  '}
        <Link color="inherit" href="/">
          PACKR
        </Link>
        {' '}
        {new Date().getFullYear()}
        .
      </Typography>
    );
  }
  return (
    <footer className="landing-page-footer">
      <Grid
        container
        className="p-5"
      >
        <Grid item xs={12} sm={8}>
          <Typography
            variant="button"
            display="block"
            className="text-uppercase font-weight-bold"
            color="inherit"
            gutterBottom
          >
            Need Some Help
          </Typography>

          <Typography variant="h2" align="left" color="inherit" gutterBottom>
            support@packrit.com
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} container>
          <Typography
            variant="button"
            display="block"
            className="text-uppercase font-weight-bold pl-2"
            gutterBottom
          >
            Join us on
          </Typography>
          <Grid item xs={12} className="sociallinks" container justify="space-around">
            <Link href="https://www.facebook.com/groups/657684838083386/" color="textPrimary" target="_blank">
              <FacebookIcon />
            </Link>
            <Link href="https://twitter.com/Packrspace" color="textPrimary" target="_blank"><TwitterIcon /></Link>
            <Link href="https://www.instagram.com/Packrspace/" color="textPrimary" target="_blank"><InstagramIcon /></Link>
            <Link href="https://www.youtube.com/playlist?list=PLdZppe-d7tNvDsbPVNOP01LH3BMd6DSHR" color="textPrimary" target="_blank"><YouTubeIcon /></Link>

          </Grid>
        </Grid>
      </Grid>
      <Copyright />
    </footer>
  );
};

export default footer;
