/* eslint-disable no-use-before-define */
import React from 'react';
import {
  TextField, InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import useRequest from 'hooks/useRequest';
import useAuth from 'hooks/useAuth';

// import { checkNumber } from 'api';
// import { get } from 'dot-prop-immutable';
import { PhoneAndroidOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton } from 'components/buttons/buttons';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';

import DialCodesDropDown from './dialCodeDropDown';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function Forgot(props) {
  const { closeForgotPassword } = props;
  const classes = useStyles();
  // const { doRequest, errors: remoteError } = useRequest({
  //   method: 'get',
  //   onSuccess: false,
  // });

  // const { successAlert, displayAlert } = useAlert();

  const validationSchema = yup.object({
    mobile: yup
      .number()
      .typeError('Invalid Mobile No.')
      .test('length', 'Invalid Mobile No.', (num) => (num ? num.toString().length > 6 : 0))
      .required('Mobile No is required'),
    // .test('isValidNumber', 'Not a valid number', async (num) => {
    //   const { valid }
    //  = await doRequest(checkNumber(num, values.dialcode ? values.dialcode.code : ''));
    //   return valid;
    // }),

  });
  const { forgotPass } = useAuth();

  const {
    handleSubmit, values, handleChange, touched, errors, isSubmitting, setFieldValue,
  } = useFormik({
    initialValues: {
      mobile: '',
      password: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (valz, actions) => {
      const {
        validate, resetForm, setFieldError, setSubmitting,
      } = actions;

      if (validate) validate(valz);

      const {
        dialcode: { phone: code }, mobile,
      } = valz;

      forgotPass({
        mobile: code + mobile,

      }).then(() => {
        resetForm();
        closeForgotPassword();
      })
        .catch(({ message }) => {
          setSubmitting(false);
          setFieldError(message.includes('Password') ? 'password' : 'mobile', message);
        });
    },
  });

  return (
    // eslint-disable-next-line react/jsx-no-duplicate-props
    <form className="pt-3" className={classes.root} noValidate autoComplete="off" className="align-self-center d-flex flex-column justify-content-around" onSubmit={handleSubmit}>
      <TextField
        label="Mobile No"
        id="mobile"
        className="mt-3"
        InputProps={{
          startAdornment: <InputAdornment position="start"><DialCodesDropDown id="dialcode" setFieldValue={setFieldValue} /></InputAdornment>,
          endAdornment: <InputAdornment position="end"><PhoneAndroidOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.mobile}
        onChange={handleChange}
        error={touched.mobile && Boolean(errors.mobile)}
        helperText={touched.mobile && errors.mobile}
      />

      <SecondaryButton
        className="mt-5 py-3"
        endIcon={<DoubleArrowOutlinedIcon />}
        type="submit"
        disabled={isSubmitting}
      >
        Submit
      </SecondaryButton>

    </form>
  );
}

export default Forgot;
