import React from 'react';
// eslint-disable-next-line import/extensions
import {
  Typography, Avatar,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
// import { Link as RouterLink } from 'react-router-dom';
import { initialPages } from 'routes/url';
import { SimpleLayout } from 'components/layouts';
import { LogoButton } from 'components/buttons';
import PackrImage from 'assets/packrit.png';
import './index.scss';

const Four04 = () => {
  const history = useHistory();
  return (
    <SimpleLayout>
      <Typography variant="h1" color="textSecondary" align="center" className="landing-page ">
        404
      </Typography>
      <Avatar alt="Packrit" src={PackrImage} variant="square" sizes="large" className="landing-page--avatar" />
      <Typography variant="h3" align="center" className="landing-page ">
        We are sorry, but the page you requested was not found.
      </Typography>

      <LogoButton
        className="landing-page--button p-3 font-weight-bold bg-gradient-secondary"
        onClick={() => {
          history.push(initialPages.landing);
        }}
      >
        Back to Home
      </LogoButton>

    </SimpleLayout>
  );
};

export default Four04;
