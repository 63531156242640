import { createSlice } from '@reduxjs/toolkit';
// import { set } from 'dot-prop-immutable';

export const initialState = { data: [], seenCount: 0 };

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearNotification(state) {
      return { ...state };
    },
    setSeenCount(state, action) {
      const { payload } = action;
      console.log('🚀 ~ file: notification.js ~ line 19 ~ setSeenCount ~ payload', payload);
      return { ...state, seenCount: payload };
    },
  },
});

export const { setNotification, clearNotification, setSeenCount } = slice.actions;

export default slice.reducer;
