import { createSlice } from '@reduxjs/toolkit';
// import { set } from 'dot-prop-immutable';

export const initialState = { data: [] };

const slice = createSlice({
  name: 'reason',
  initialState,
  reducers: {
    setReason(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    cleaReason(state) {
      return { ...state };
    },
  },
});

export const { setReason, cleaReason } = slice.actions;

export default slice.reducer;
