import React, { useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './header';
import Hero from './hero';
import Second from './second';
import Third from './third';
import Fourth from './fourth';
import Footer from './footer';
import './index.scss';

export default function Home() {
  const aboutUs = useRef(null);
  const contactUs = useRef(null);
  const ourApp = useRef(null);
  const executeScroll = (ref) => {
    console.log(ref);
    if (ref && ref.current) ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <>
      <CssBaseline />
      <div className="px-4 px-md-5 landing-page-firstblock">
        <Header toAbout={() => executeScroll(aboutUs)} toContact={() => executeScroll(contactUs)} />
        <Hero toApp={() => executeScroll(ourApp)} />
      </div>
      <div ref={aboutUs} className="px-4 px-md-5  landing-page-secondblock">
        <Second />
      </div>
      <div ref={ourApp} className="px-4 px-md-5  landing-page-thirdblock">
        <Third />
      </div>
      <div className="px-4 px-md-5  landing-page-fourthblock">
        <Fourth />
      </div>

      {/* Footer */}
      <div ref={contactUs}>
        <Footer />
      </div>
      {/* End footer */}
    </>
  );
}
