/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
// import { set } from 'dot-prop-immutable';
// My Order
export const initialState = { data: [] };

const slice = createSlice({
  name: 'acceptedOrder',
  initialState,
  reducers: {
    setAcceptedOrder(state, action) {
      const { payload } = action;
      return { ...state, data: [...payload] };
    },
    clearOrder(state) {
      return { ...state, order: null };
    },
    setAcceptedOrderById(state, action) {
      const { payload } = action;
      console.log('🚀 ~ file: acceptedOrder.js ~ line 20 ~ setAcceptedOrderById ~ payload', payload);

      return { ...state, data: [...state.data, { ...payload }] };
    },
    updateAcceptedOrder(state, action) {
      const { payload } = action;
      const forder = state.data.filter((ord) => ord._id === payload._id);
      if (forder.length < 1) {
        return {
          ...state,
          data: [{ ...payload }, ...state.data],
        };
      }
      return {
        ...state,
        data: state.data.map((item) => {
        // Replace the item
          if (item._id === payload._id) {
            return payload;
          }

          // Leave every other item unchanged
          return item;
        }),
      };
    },

  },
  deleteAcceptedOrder(state, action) {
    const { payload } = action;

    return {
      ...state,
      data: state.data.map((item, i) => {
        // Remove the item
        if (item._id === payload._id) {
          state.data.splice(i, 1);
        }

        // Leave every other item unchanged
        return item;
      }),
    };
  },
});

export const {
  setAcceptedOrder, clearOrder, setAcceptedOrderById, updateAcceptedOrder, deleteAcceptedOrder,
} = slice.actions;

export default slice.reducer;
