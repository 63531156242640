/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import {
  TextField, InputAdornment, Checkbox, FormControlLabel, Link,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useQueryString from 'hooks/useQueryString';
import useRequest from 'hooks/useRequest';
import useAuth from 'hooks/useAuth';
import useRedirect from 'hooks/useRedirect';
import { Link as RouterLink } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
// import { checkNumber } from 'api';
// import { get } from 'dot-prop-immutable';
import OTPbox from 'components/modals/otp';
import { PhoneAndroidOutlined, LockOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton } from 'components/buttons/buttons';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import DialCodesDropDown from './dialCodeDropDown';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Signup() {
  const classes = useStyles();
  const [, routeTo] = useRedirect();
  const [OTPModal, setOTPModal] = useState(false);
  const { errors: remoteError } = useRequest({
    method: 'post',
    onSuccess: false,
  });

  const validationSchema = yup.object({
    fullName: yup
      .string('Enter your user name')
      .min(5, 'Atleast 5 characters are required')
      // .max(8, 'Atleast 8 characters are required')
      .required('User Name is required'),
    mobile: yup
      .number()
      .typeError('Invalid Mobile No.')
      .test('length', 'Invalid Mobile No.', (num) => (num ? num.toString().length > 6 : 0))
    // .test('isValidNumber', 'Not a valid number', async (num) => {
    //   const { valid } = await doRequest(checkNumber(num, values.dialcode ?
    // values.dialcode.code : ''));
    //   return valid;
    // }),
      .required('Mobile No is required'),
    password: yup
      .string('Enter your password')
      .min(5, 'Atleast 5 characters are required')
      .max(10, 'Maximum 10 characters long')
      .required('Password is required'),
    email: yup
      .string()
      .email('Invalid Email.')
      .required('Email is Required'),
    confirmPassword: yup
      .string('Enter your password')
      .min(5, 'Invalid password')
      .required('confirm password is required')
      .test('isConfirm', 'password are not same', (cpass) => cpass === values.password),
  });
  const { signUp } = useAuth();

  const {
    handleSubmit, values, handleChange, touched, errors, isSubmitting, setFieldValue,
  } = useFormik({
    initialValues: {
      mobile: '',
      password: '',
      fullName: '',
      email: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (valz, {
      validate, setFieldError, setSubmitting,
    }) => {
      if (validate) validate(valz);

      const {
        mobile, password, fullName, email,
      } = valz;

      setFieldValue('phoneNum', mobile);
      signUp({
        mobile,
        password,
        userName: fullName,
        email,
      }).then(() => {
        setOTPModal(true);
        // resetForm();
      })
        .catch(({ message }) => {
          setSubmitting(false);
          setFieldError(message.includes('Password') ? 'password' : 'mobile', message);
        });
    },
  });
  const [params, setParams] = useQueryString();
  const handleTermsConditionModal = () => {
    setParams({ termCondition: true });
    // setTermsAndConditions(true);
  };

  const termsAndConditions = params.termsAccepted;

  return (
    // eslint-disable-next-line react/jsx-no-duplicate-props
    <form className="pt-3" className={classes.root} noValidate autoComplete="off" className="align-self-center d-flex flex-column justify-content-around" onSubmit={handleSubmit}>
      {remoteError}

      <TextField
        id="fullName"
        label="Full Name"
        type="text"
        className="mt-3"
        autoComplete="fullName"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.fullName}
        onChange={handleChange}
        error={touched.fullName && Boolean(errors.fullName)}
        helperText={touched.fullName && errors.fullName}
      />
      <TextField
        id="email"
        label="Email"
        type="text"
        className="mt-3"
        autoComplete="email"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.email}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
      <div className={`position-relative mt-4 ${errors.mobile ? 'error-field' : ''}`}>
        <PhoneInput
          id="mobile"
          international
          country="pk"
          inputProps={{
            name: 'mobile',
            required: true,
            autoFocus: true,
            className: errors.mobile ? 'form-control error  w-100' : 'form-control  w-100',
          }}
          isValid={
            (inputNumber, country, countries) => countries.some(
              (countryi) => _.startsWith(inputNumber, countryi.dialCode)
              || _.startsWith(countryi.dialCode, inputNumber),
            )
}
          enableSearch
          value={values.mobile}
          onChange={(val, data) => {
            console.log(data);
            console.log(val);
            return setFieldValue('mobile', `+${val}`);
          }}
          error={touched.mobile && Boolean(errors.mobile)}
          helperText={touched.mobile && errors.mobile}

        />
        <PhoneAndroidOutlined className="endicon" />
        {errors.mobile && <p className="error-msg ml-3" id="password-helper-text">{errors.mobile}</p>}
      </div>
      {/* <TextField
        label="Mobile No"
        id="mobile"
        className="mt-3"
        InputProps={{
          startAdornment: <InputAdornment position="start">
          <DialCodesDropDown id="dialcode" setFieldValue={setFieldValue} /></InputAdornment>,
          endAdornment: <InputAdornment position="end"><PhoneAndroidOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.mobile}
        onChange={handleChange}
        error={touched.mobile && Boolean(errors.mobile)}
        helperText={touched.mobile && errors.mobile}
      /> */}
      <TextField
        id="password"
        label="Password"
        type="password"
        className="mt-3"
        autoComplete="current-password"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.password}
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
      />

      <TextField
        id="confirmPassword"
        label="Confirm Password"
        type="password"
        className="mt-3"
        autoComplete="confirmPassword"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.confirmPassword}
        onChange={handleChange}
        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
        helperText={touched.confirmPassword && errors.confirmPassword}
      />
      <FormControlLabel
        control={<Checkbox value="uncontrolled" inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} onClick={handleTermsConditionModal} checked={termsAndConditions || false} />}
        label={(
          <div>
            I agree with the Terms & Conditions and the
            {' '}
            <Link component={RouterLink} to={routeTo('privacyPolicy')} underline="None">Privacy Policy</Link>
            {' '}
            of PACKR
          </div>
)}
      />
      <SecondaryButton
        className="mt-5 py-3"
        endIcon={<DoubleArrowOutlinedIcon />}
        type="submit"
        disabled={isSubmitting || !termsAndConditions}
      >
        Continue
      </SecondaryButton>
      <OTPbox
        open={OTPModal}
        handleClose={() => setOTPModal(false)}
        phone={values.phoneNum || ''}
        email={values.email || ''}
      />

    </form>
  );
}

export default Signup;
