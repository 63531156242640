import React, { useState } from 'react';
import DialogBase from 'components/modals/base';
import { Typography, Button } from '@material-ui/core';
import { vserifyphone, resendSMSToken } from 'api';
import useRequest from 'hooks/useRequest';
import useRedirect from 'hooks/useRedirect';
import useAlert from 'hooks/useAlert';
import useAuth from 'hooks/useAuth';
import { SecondaryButton } from 'components/buttons/buttons';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';
import OtpInput from 'react-otp-input';
import 'components/modals/otp/index.scss';

// import useQueryString from 'hooks/useQueryString';

const Index = ({
  open, handleClose, phone, email,
}) => {
  const { doRequest, errors: remoteError } = useRequest({
    method: 'post',
    onSuccess: false,
  });
  const [redirectTo] = useRedirect();
  const [otpstate, setOtpstate] = useState('');
  const [error, setError] = useState(null);
  const { signinStore } = useAuth();
  // const { successAlert, displayAlert } = useAlert();
  const handleChange = (otp) => setOtpstate(otp);

  const handleSubmit = () => doRequest(vserifyphone(),
    { email, mobile: phone, token: otpstate })
    .then(({ success, data }) => {
      if (success) {
        signinStore(data);
        return redirectTo('dashboard');
      } setError('Opps Something went wrong');
      return redirectTo('Login');
    })
    .catch(({ data }) => {
      setError(data);
      setOtpstate('');
      setTimeout(() => {
        setError(data);
      }, 2000);
    });

  const [, , successAlert, , displayAlert] = useAlert();
  const resendCode = () => doRequest(resendSMSToken(), { email, mobile: phone })
    .then(({ success, message }) => ((success ? successAlert(message) : setError('Opps Something went wrong'))))
    .catch(({ message }) => setError(message));

  return (
    <DialogBase
      title={<Typography variant="h4" className="mt-3 align-self-center" color="textSecondary" align="center">OTP Verification</Typography>}
      open={open}
      handleClose={handleClose}
      maxWidth="xs"
      fullWidth
      disableBackdropClick
    >
      <div className="mx-sm-3 d-flex flex-column justify-content-center">

        <Typography variant="body2" className="my-3  align-self-center " align="center" color="textPrimary">
          We sent your code to
          {' '}
          {email}
          <Typography display="block" align="center" color="textPrimary" component="span">
            This code will expire in
            {' '}
            <Typography display="inline" align="center" color="secondary" component="span">5 minutes</Typography>
          </Typography>
        </Typography>
        {Array.isArray(remoteError) ? remoteError[0].msg : remoteError}

        <OtpInput
          id="otp"
          value={otpstate}
          onChange={handleChange}
          numInputs={4}
          isInputNum
          containerStyle="input-style3"
      //  separator={<span> &nbsp;</span>}
          inputStyle="input-style"
          hasErrored={Boolean(error)}
        />
        <Button className="mt-3" onClick={resendCode}>Resend OTP Code</Button>

        <SecondaryButton
          className="mt-4 py-3"
          endIcon={<DoubleArrowOutlinedIcon />}
          type="button"
          onClick={handleSubmit}
        >
          Verify
        </SecondaryButton>
        {displayAlert}
      </div>
    </DialogBase>
  );
};

export default Index;
