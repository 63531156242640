import React from 'react';
import {
  IconButton,
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import SeachBox from 'components/searchbox';
import SearchIcon from '@material-ui/icons/Search';

const SearchButton = ({ ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simplepopover' : undefined;
  return (
    <>
      <IconButton
        edge="end"
        aria-label="menu"
        onClick={handleClick}
        {...rest}
      >
        <SearchIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="teal-search_panel"
      >
        <div className="px-3 py-2 teal-search_panel">
          <SeachBox className="p-3" />
        </div>
      </Popover>
    </>
  );
};

export default SearchButton;
