/* eslint-disable quote-props */
import axios from 'axios';
import { useState } from 'react';
import { get } from 'dot-prop-immutable';
import { auth as authSelector } from 'state/selectors/auth';
import { useSelector } from 'react-redux';
import useAlert from 'hooks/useAlert';
import useSignOut from 'hooks/useSignOut';

export default ({
  url = '', method = 'get', body = '', onSuccess = false, onError = true,
}) => {
  const [errors, setErrors] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [errorAlert] = useAlert();
  const auth = useSelector(authSelector);
  const signOut = useSignOut();

  // eslint-disable-next-line consistent-return
  const doRequest = async (iurl, ibody, imethod, isAuth = false) => {
    try {
      setErrors(null);
      const token = isAuth && auth.user ? `Bearer ${auth.user.data.access_token}` : '';
      // const response = await axios[imethod || method](url || iurl, body || ibody, {
      //   headers: { 'Authorization': token, 'Content-Type': 'application/json' },
      // });
      const data = JSON.stringify(body || ibody);

      const config = {
        method: imethod || method,
        url: url || iurl,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        data,
      };

      const response = await axios(config);

      if (onSuccess) {
        onSuccess(response.data);
      }
      setSuccessMsg(
        response.data.message,
      );

      return response.data;
    } catch (err) {
      let errmsg;
      if (err.response && err.response.data) {
        errmsg = Array.isArray(err.response.data.errors)

          ? err.response.data.errors.map((eobj) => eobj.msg)
          : undefined;
      }

      setErrors(
        err.response ? errorAlert(errmsg ? errmsg.join('-') : get(err, 'response.data.message') || get(err, 'response.data.errors') || 'Opps!! something went wrong.') : errorAlert(err.message),

      );
      // invalid token signout
      const emsgr = get(err, 'response.data.message');
      if (emsgr && emsgr.toLowerCase().includes('token')) signOut();

      if (onError) throw new Error(errmsg ? errmsg.join('-') : get(err, 'response.data.message') || get(err, 'response.data.errors') || 'Opps!! something went wrong.' || err.message);

      /* if (onError) {
        throw new Error(errmsg ? errmsg.join('-')
          : (!Array.isArray(get(err, 'response.data.message')) && get(err, 'response.data.message'))
      || get(err, 'response.data.errors')
      || err.message || 'Opps!! something went wrong.');
      } */
    }
  };

  // eslint-disable-next-line consistent-return
  const doAuthRequest = async (iurl, ibody, imethod) => {
    try {
      if (auth && auth.user && auth.user.data.access_token) {
        setErrors(null);
        const token = `Bearer ${auth.user.data.access_token}`;

        const data = JSON.stringify(body || ibody);

        const config = {
          method: imethod || method,
          url: url || iurl,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          data,
        };

        const response = await axios(config);

        if (onSuccess) {
          onSuccess(response.data);
        }
        setSuccessMsg(
          response.data.message,
        );

        return response.data;
      }
    } catch (err) {
      let errmsg;
      if (err.response && err.response.data) {
        errmsg = Array.isArray(err.response.data.errors)

          ? err.response.data.errors.map((eobj) => eobj.msg)
          : undefined;
      }

      setErrors(
        err.response ? errorAlert(errmsg ? errmsg.join('-') : get(err, 'response.data.errors') || get(err, 'response.data.message') || 'Opps!! something went wrong.') : errorAlert(err.message),

      );
      // invalid token signout
      const emsgr = get(err, 'response.data.message');
      if (emsgr.toLowerCase().includes('token')) signOut();

      if (onError) throw new Error(errmsg ? errmsg.join('-') : get(err, 'response.data.errors') || get(err, 'response.data.message') || 'Opps!! something went wrong.' || err.message);
    }
  };

  return {
    doRequest, errors, successMsg, doAuthRequest,
  };
};
