import React, { useRef } from 'react';
import { Grid, Fab } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Reviewcard from './reviewcard';
import ReviewsData from './reviews.json';

const Slideshow = () => {
  const scrollRef = useRef(null);
  console.log(ReviewsData);
  const scroll = (scrollOffset) => {
    if (scrollRef.current) scrollRef.current.scrollLeft += scrollOffset;
  };

  return (
    <>
      <Grid item xs={12} sm={6} container justify="flex-end">
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          className="mr-1"
          onClick={() => {
            scroll(-220);
          }}
        >
          <ArrowBackIcon />
        </Fab>
        <Fab color="primary" aria-label="add" size="small" onClick={() => { scroll(200); }}>
          <ArrowForwardIcon />
        </Fab>
      </Grid>
      <div
        ref={scrollRef}
        className="d-flex justify-content-between py-3 px-0 px-sm-3 landing-page--scrolling"
      >
        {ReviewsData.reviews.map((review) => <Reviewcard data={review} />)}

      </div>
    </>
  );
};

export default Slideshow;
