import React from 'react';
import {
  Link, Typography,
} from '@material-ui/core';
import { TealLayout } from 'components/layouts';
import LoginForm from 'components/forms/login';
import { Link as RouterLink } from 'react-router-dom';
import ForgotPasswordButton from 'components/buttons/forgotBtn';
import { initialPages } from 'routes/url';
import './index.scss';

const index = () => (
  <TealLayout title="Login">
    <div className="d-flex justify-content-around flex-column w-md-50 mt-5 h-75">

      <LoginForm />

      <ForgotPasswordButton fullWidth={false} className="mt-5 align-self-center" />
      <Typography variant="subtitle1" align="center" className="mt-5">
        Don’t have an account?
        {' '}

        <Link component={RouterLink} to={initialPages.signUp} color="secondary">
          Sign Up
        </Link>
      </Typography>
      <Typography variant="subtitle1" align="center" className="mt-5">
        <Link component={RouterLink} to="/" color="secondary">
          Back To Home
        </Link>
      </Typography>
    </div>

  </TealLayout>
);

export default index;
