/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import useQueryString from 'hooks/useQueryString';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ReactComponent as Logo } from 'assets/logosmall.svg';

// eslint-disable-next-line import/prefer-default-export
export const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#fff',
    borderColor: '#0063cc',
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#fff',
    },
    '&:active': {
      borderColor: '#fff',
    },
    '&:focus': {
      borderColor: 'none',
    },
  },
}))(Button);

// eslint-disable-next-line import/prefer-default-export
export const FButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#5065b1'),
    backgroundColor: '#5065b1',
    borderColor: '#5065b1',
    '&:hover': {
      backgroundColor: '#5065b1',
      borderColor: '#5065b1',
    },
    '&:active': {
      borderColor: '#5065b1',
    },
    '&:focus': {
      borderColor: 'none',
    },
  },
}))(Button);

// eslint-disable-next-line react/prop-types
export const SecondaryButton = ({ className, children, ...rest }) => (
  <Button
    color="secondary"
    variant="contained"
    className={`bg-gradient-secondary ${className}`}
    {...rest}
  >
    {children}
  </Button>
);

// eslint-disable-next-line react/prop-types
export const PrimaryButton = ({ className, children, ...rest }) => (
  <Button
    color="primary"
    variant="contained"
    className={`bg-gradient-primary ${className}`}
    {...rest}
  >
    {children}
  </Button>
);

// eslint-disable-next-line react/prop-types
export const LogoButton = ({ className, children, ...rest }) => (
  <SecondaryButton
    color="secondary"
    variant="contained"
    className={className}
    startIcon={<Logo className="mr-2" style={{ height: '30px', width: '35px' }} />}
    {...rest}
  >
    {children}
  </SecondaryButton>
);

// eslint-disable-next-line react/prop-types
export const ForgotPasswordButton = ({ ...rest }) => {
  const [, setParams] = useQueryString();
  const handleClick = async () => {
    setParams({ forgotPassword: true });
  };
  return (
    <Button {...rest} onClick={handleClick}>Forgot Password ?</Button>
  );
};

// eslint-disable-next-line react/prop-types
export const GreyButton = ({ className, children, ...rest }) => (
  <Button
    color="gray"
    variant="contained"
    className={className}
    {...rest}
  >
    {children}
  </Button>
);

// eslint-disable-next-line import/prefer-default-export
export const WhitePrimaryButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary,
    backgroundColor: '#fff',
    borderColor: '#0063cc',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      borderColor: '#fff',
      color: '#fff',
    },
    '&:active': {
      borderColor: '#fff',
    },
    '&:focus': {
      borderColor: 'none',
    },
  },
}))(Button);

// eslint-disable-next-line import/prefer-default-export
export const WhiteOutlineButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: theme.palette.primary,
    borderColor: '#fff',
    '&:hover': {
      borderColor: theme.palette.secondary.light,
      color: theme.palette.secondary.light,

    },
    '&:active': {
      borderColor: theme.palette.secondary.light,
    },
    '&:focus': {
      borderColor: 'none',
    },
  },
}))(Button);
