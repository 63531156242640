import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

export default function skeletonCard() {
  return (
    <Card className="dashboard_card">
      <div className="d-flex flex-row">
        <Skeleton animation="wave" variant="square" width={80} height={85} />
        <CardContent className="dashboard_card-content">
          <Skeleton animation="wave" height={14} width="90%" style={{ marginTop: 20 }} />
        </CardContent>
        <Skeleton animation="wave" variant="square" width={80} height={85} />
      </div>

    </Card>
  );
}
