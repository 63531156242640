import React from 'react';
import DialogBase from 'components/modals/base';
// import useQueryString from 'hooks/useQueryString';
import { PrimaryButton } from 'components/buttons/buttons';
import useRedirect from 'hooks/useRedirect';
import { ReactComponent as Order } from 'assets/order.svg';
import { ReactComponent as Delivery } from 'assets/delivery.svg';

const Index = ({ open, closeit }) => {
  // const [params, setParams] = useQueryString();
  const [redirect] = useRedirect();
  const handleClose = () => {
    // setParams({ newOrder: undefined });
    closeit();
  };

  return (
    <DialogBase
      title=""
      open={open}
      handleClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <div className="mx-sm-5 my-5 d-flex flex-column">
        <PrimaryButton
          className="py-3 mb-3 text-uppercase"
          startIcon={<Order className="mr-2 " style={{ height: '30px' }} />}
          onClick={() => redirect('addOrder')}
        >
          I want to Order
        </PrimaryButton>
        <PrimaryButton
          className="py-3 text-uppercase"
          startIcon={<Delivery className="mr-2" style={{ height: '30px' }} />}
          onClick={() => redirect('addOrderSend')}
        >
          I want to Send
        </PrimaryButton>
      </div>
    </DialogBase>
  );
};

export default Index;
