import { createSlice } from '@reduxjs/toolkit';
// import { set } from 'dot-prop-immutable';
// My Order
export const initialState = { order: null };

const slice = createSlice({
  name: 'requestedOrder',
  initialState,
  reducers: {
    setRequestedOrder(state, action) {
      const { payload } = action;
      return { ...state, order: payload };
    },
    clearOrder(state) {
      return { ...state, order: null };
    },
  },
});

export const {
  setRequestedOrder, clearOrder,
} = slice.actions;

export default slice.reducer;
