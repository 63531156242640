/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import {
  TextField, InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useRequest from 'hooks/useRequest';
import useAuth from 'hooks/useAuth';
import { LockOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { SecondaryButton } from 'components/buttons/buttons';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';
import useQueryString from 'hooks/useQueryString';
import useAlert from 'hooks/useAlert';
import useRedirect from 'hooks/useRedirect';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

function ResetPassword() {
  const classes = useStyles();
  const { errors: remoteError } = useRequest({
    method: 'post',
    onSuccess: false,
  });
  const [params] = useQueryString();

  const validationSchema = yup.object({
    password: yup
      .string('Enter your password')
      .min(5, 'Atleast 5 characters are required')
      .max(10, 'Maximum 10 characters long')
      .required('Password is required'),
    confirmPassword: yup
      .string('Enter your password')
      .min(5, 'Atleast 5 characters are required')
      .max(10, 'Maximum 10 characters long')
      .required('Password is required')
      .test('isConfirm', 'password are not same', (cpass) => cpass === values.password),
  });
  const { resetPassword } = useAuth();
  const [redirectTo] = useRedirect();
  const [DisplayError, setDisplayError] = useState('');
  const [errorAlert,, successAlert, , displayAlert] = useAlert();

  const {
    handleSubmit, values, handleChange, touched, errors, isSubmitting,
  } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (valz, {
      validate, resetForm, setFieldError, setSubmitting,
    }) => {
      if (validate) validate(valz);

      const { password, confirmPassword } = valz;
      // eslint-disable-next-line no-console
      resetPassword({
        token: params.token,
        newPassword: password,
        confirmPassword,
      })
        .then(({ success, message }) => {
          if (success === true) {
            successAlert(message);
            setTimeout(() => {
              resetForm();
              redirectTo('signIn');
            }, 2000);
          }
        })
        .catch(({ message }) => {
          setSubmitting(false);
          setFieldError(message.includes('Password') ? 'password' : 'mobile', message);
          setDisplayError(message);
          setTimeout(() => {
            setDisplayError('');
          }, 2000);
        });
    },
  });

  return (
    // eslint-disable-next-line react/jsx-no-duplicate-props
    <form className="pt-3" className={classes.root} noValidate autoComplete="off" className="align-self-center d-flex flex-column justify-content-around" onSubmit={handleSubmit}>
      {remoteError}
      {DisplayError && errorAlert(DisplayError) }
      {displayAlert}
      <TextField
        id="password"
        label="Password"
        type="password"
        className="mt-3"
        autoComplete="password"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.password}
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
      />

      <TextField
        id="confirmPassword"
        label="Confirm Password"
        type="password"
        className="mt-3"
        autoComplete="confirmPassword"
        InputProps={{
          startAdornment: <InputAdornment position="start"><></></InputAdornment>,
          endAdornment: <InputAdornment position="end"><LockOutlined /></InputAdornment>,
        }}
        variant="outlined"
        value={values.confirmPassword}
        onChange={handleChange}
        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
        helperText={touched.confirmPassword && errors.confirmPassword}
      />

      <SecondaryButton
        className="mt-5 py-3"
        endIcon={<DoubleArrowOutlinedIcon />}
        type="submit"
        disabled={isSubmitting}
      >
        Submit
      </SecondaryButton>

    </form>
  );
}

export default ResetPassword;
