/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React from 'react';
import {
  TextField, InputAdornment, Typography, Grid, Box,
  /* Radio, RadioGroup, */ FormControlLabel, Checkbox,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import useRequest from 'hooks/useRequest';
import useOrder from 'hooks/order/useOrder';
import moment from 'moment';
import CityCountry from 'components/cityCountry';
import useRedirect from 'hooks/useRedirect';
import { SecondaryButton } from 'components/buttons/buttons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import useQueryString from 'hooks/useQueryString';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';
import 'views/order/index.scss';
import { uploadImage, receiverUploadImage } from 'api/index';
import ImageUpload from 'components/buttons/imageUpload';
import PhoneInput from 'react-phone-input-2';
import DialCodesDropDown from './dialCodeDropDown';

function Order() {
  const isSendOrder = window.location.pathname.match(/send/gi);

  const validationSchema = yup.object({
    from: yup
      .string()
      .min(3, 'Invalid')
      .required('Required'),

    to: yup
      .string()
      .min(3, 'Invalid')
      .required('Required'),

    fromDate: yup
      .string()
      .min(3, 'Invalid')
      .required('Required'),

    description: yup
      .string()
      .max(300, 'Too Long !! only 300 characters allowed.'),

    paymentDetail: yup.object().shape({
      cardNumber: yup.string()
        .min(5, 'Too Short')
        .max(20, 'Too Long'),
    }),
    weight: yup
      .number()
      .max(500, 'too much!!')
      .min(1, 'Invalid Value')
      .required(),

  });
  const [add, error] = useOrder();
  const [redirectTo] = useRedirect();

  const {
    handleSubmit, values, handleChange, touched, errors, isSubmitting, setFieldValue,
  } = useFormik({
    initialValues: {
      name: '',
      from: '',
      to: '',
      weight: '',
      description: '',
      type: isSendOrder ? 'Send' : 'Order',
      paymentType: 'basic',
      fromDate: moment().format(),
      toDate: moment().format(),
      isMySelf: false,
      dialcode: { phone: '00' },
      receiverMobile: '00',
      images: [],
      paymentDetail: {
        cardNumber: '',
        cvv: '',
        expireDate: '',
        cardHolderName: '',
        bank: '',
        iban: '',
      },
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (valz, {
      validate, resetForm, setFieldError, setSubmitting,
    }) => {
      if (validate) validate(valz);

      const {
        receiverMobile, fromDate, toDate, paymentDetail,
      } = valz;
      valz.fromDate = moment(fromDate).format('D/MM/yyyy');
      valz.toDate = moment(toDate).format('D/MM/yyyy');
      paymentDetail.expireDate = paymentDetail.expireDate && moment(paymentDetail.expireDate).format('D/MM/yyyy');
      add({
        ...valz,
        receiverMobile,

      }).then(({ data }) => {
        resetForm();
        // eslint-disable-next-line no-underscore-dangle
        redirectTo('orderDetail', { id: data._id });
      }).catch(({ message }) => {
        setSubmitting(false);
        setFieldError(message);
      });
    },
  });
  const [params, setParams] = useQueryString();
  const handleTermsConditionModal = () => {
    setParams({ termCondition: true });
    // setTermsAndConditions(true);
  };

  const setDefaultCityCountry = (setInput) => {
    if (!isSendOrder) setInput({ name: 'Any City, Country' });
  };

  const termsAndConditions = params.termsAccepted;

  return (

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {error}
      <form className="order_form" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container justify="center" spacing={1} direction="row">
          <Grid item xs={12} md={11}>
            <Grid container justify="space-between" spacing={2} direction="row">
              <Grid item xs={12} md={6}>
                <Grid container justify="space-between" direction="row">
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Earliest Date To Receive Your Order?"
                      id="fromDate"
                      value={values.fromDate}
                      onChange={(value) => {
                        setFieldValue('fromDate', value);
                        setFieldValue('toDate', value);
                      }}
                      defaultValue=" "
                      placeholder="dd/mm/yy"
                      inputVariant="outlined"
                      animateYearScrolling
                      format="d/MMM/yyyy"
                      disablePast
                      error={Boolean(errors.fromDate)}
                      helperText={errors.fromDate}
                      minDate={new Date()}
                      maxDate={moment().add(45, 'days')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Last Date To Receive Your Order?"
                      id="toDate"
                      value={values.toDate}
                      placeholder="dd/mm/yy"
                      inputVariant="outlined"
                      animateYearScrolling
                      format="d/MMM/yyyy"
                      disablePast
                      onChange={(value) => {
                        setFieldValue('toDate', value);
                      }}
                      error={touched.toDate && Boolean(errors.toDate)}
                      helperText={touched.toDate && errors.toDate}
                      minDate={values.fromDate}
                      maxDate={moment().add(45, 'days')}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="space-between" direction="row">
                  <Grid item xs={12} md={6}>
                    <CityCountry
                      setFieldValue={setFieldValue}
                      errors={errors}
                      name="from"
                      label="Where would You Like To Order From?"
                      setDefaultValue={setDefaultCityCountry}
                      isSendOrder={isSendOrder}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CityCountry setFieldValue={setFieldValue} errors={errors} name="to" label="Where Would You Like Your Order Delivered?" />
                  </Grid>
                </Grid>
                <Grid container justify="space-between" direction="row">
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="weight"
                      label="How Much Weight Do You Need?"
                      type="number"
                      placeholder="KG"
           // className="mt-3 mr-3"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><></></InputAdornment>,

                      }}
                      variant="outlined"
                      value={values.weight}
                      onChange={handleChange}
                      error={touched.weight && Boolean(errors.weight)}
                      helperText={touched.weight && errors.weight}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={3}>

                <CityCountry
                  setFieldValue={setFieldValue}
                  errors={errors}
                  name="from"
                  label="Depature"
                  setDefaultValue={setDefaultCityCountry}
                  isSendOrder={isSendOrder}
                />
                <TextField
                  id="weight"
                  label="Weight"
                  type="number"
                  placeholder="KG"
           // className="mt-3 mr-3"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,

                  }}
                  variant="outlined"
                  value={values.weight}
                  onChange={handleChange}
                  error={touched.weight && Boolean(errors.weight)}
                  helperText={touched.weight && errors.weight}
                />
                <CityCountry setFieldValue={setFieldValue} errors={errors} name="to" label="arrival" />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <TextField
                  id="description"
                  label="Would You Like To Tell Us More?"
                  multiline
                  rows={6}
           // className="mt-3 mr-3"
                  variant="outlined"
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                    maxLength: 300,
                  }}
                />

                <Box
                  border={1}
                  borderRadius={10}
                  minHeight={75}
                  margin={2}
                  className="order_form-box d-flex justify-content-around flex-wrap"
                  borderColor="initial"
                  marginTop={1}
                  padding={2}
                  paddingBottom={0}
                  marginRight={4}
                  position="relative"
                >
                  <ImageUpload id="images[0]" uploadEndPoint={uploadImage()} setFieldValue={setFieldValue} name="images[0]" responseField="imageUrl" />
                  <ImageUpload id="images[1]" uploadEndPoint={uploadImage()} setFieldValue={setFieldValue} name="images[1]" responseField="imageUrl" />
                  <ImageUpload id="images[2]" uploadEndPoint={uploadImage()} setFieldValue={setFieldValue} name="images[2]" responseField="imageUrl" />
                  <ImageUpload id="images[3]" uploadEndPoint={uploadImage()} setFieldValue={setFieldValue} name="images[3]" responseField="imageUrl" />
                  <Typography variant="caption" display="block" gutterBottom className="order_form-box-label" color="primary">
                    Would You Like To Add Any Pictures?
                  </Typography>
                </Box>

              </Grid>
            </Grid>
          </Grid>
          {isSendOrder
            && (
            <>
              {' '}
              <Grid item xs={12} md={6}>
                <Typography variant="h5" className="text-uppercase pt-3 mt-3" display="block" color="primary" gutterBottom>
                  Receiver information
                </Typography>
              </Grid>

              <Grid item xs={10} md={5} className="d-flex justify-content-md-end">
                {/* {!isSendOrder
            && (
            <Typography variant="body2" className="pt-3 mt-3">
              <Switch
                checked={values.isMySelf}
                onChange={handleChange}
                name="isMySelf"
                inputProps={{ 'aria-label': 'isMySelf' }}
              />
              {' '}
              Order For Myself
            </Typography>
            ) } */}
              </Grid>

              <Grid item xs={12} md={11} className="d-flex flex-row ml-3">
                <ImageUpload uploadEndPoint={receiverUploadImage()} setFieldValue={setFieldValue} name="receiverImage" responseField="imageUrl" disabled={values.isMySelf} />
                <Typography variant="body2" className="pt-3">
                  Add Photo
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} className="d-flex align-center">
                <TextField
                  id="receiverFullName"
                  label="Who Will Receive The Parcel"
                  type="text"
                  className="mt-3"
                  autoComplete="fullName"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                  }}
                  variant="outlined"
                  disabled={values.isMySelf}
                  value={values.receiverFullName}
                  onChange={handleChange}
                  error={touched.receiverFullName && Boolean(errors.receiverFullName)}
                  helperText={touched.receiverFullName && errors.receiverFullName}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <PhoneInput
                  id="receiverMobile"
                  international
                  country="pk"
                  inputProps={{
                    name: 'receiverMobile',
                    required: true,
                    className: errors.receiverMobile ? 'form-control error mt-2   ' : 'form-control  mt-2',
                  }}
                  enableSearch
                  value={values.mobile}
                  onChange={(val, data) => {
                    console.log(data);
                    console.log(val);
                    return setFieldValue('receiverMobile', `+${val}`);
                  }}
                  error={touched.receiverMobile && Boolean(errors.receiverMobile)}
                  helperText={touched.receiverMobile && errors.receiverMobile}
                />

                {errors.receiverMobile && <p className="error-msg" id="password-helper-text">{errors.receiverMobile}</p>}
                {/* <TextField
                  label="Mobile No"
                  id="receiverMobile"
                  className="mt-3"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><DialCodesDropDown id="dialcode" setFieldValue={setFieldValue} /></InputAdornment>,
                    endAdornment: <>{' '}</>,
                  }}
                  variant="outlined"
                  value={values.receiverMobile}
                  disabled={values.isMySelf}
                  onChange={handleChange}
                  error={touched.receiverMobile && Boolean(errors.receiverMobile)}
                  helperText={touched.receiverMobile && errors.receiverMobile}
                /> */}
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={11} />
            </>
            )}

          {/* <Grid item xs={12} md={11}>
            <Typography variant="h5" className="text-uppercase pt-3 mt-3" display="block" color="primary" gutterBottom>
              Which service do you want to use?
            </Typography>

          </Grid>
          <Grid item xs={11} md={4}>
            <RadioGroup row aria-label="paymentType" name="paymentType" defaultValue="basic" onChange={handleChange}>
              <FormControlLabel
                value="premium"
                control={<Radio color="secondary" />}
                label="Premium"
                labelPlacement="end"
              />
              <FormControlLabel
                value="basic"
                control={<Radio color="secondary" />}
                label="Basic"
                labelPlacement="end"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={0} md={6} />
          {values.paymentType === 'premium' && (
          <>
            <Grid item xs={12} md={3}>
              <Grid container justify="space-between" direction="column">
                <TextField
                  label="Card Number"
                  id="paymentDetail.cardNumber"
           // className="paymentDetailmt-3 mr-2"
                  placeholder="000 000 000 000 00"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                  }}
                  onChange={handleChange}
                  value={values.paymentDetail && values.paymentDetail.cardNumber}
                  error={touched.paymentDetail && (touched.paymentDetail.cardNumber
                && Boolean(errors.paymentDetail && errors.paymentDetail.cardNumber))}
                  helperText={(touched.paymentDetail && touched.paymentDetail.cardNumber)
                 && (errors.paymentDetail && errors.paymentDetail.cardNumber)}
                />
                <TextField
                  label="CVV"
                  id="paymentDetail['cvv']"
           // className="mt-3 mr-2"
                  placeholder="0000"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                  }}
                  value={values.paymentDetail.cvv}
                  onChange={handleChange}
                  error={(touched.paymentDetail && touched.paymentDetail.cvv)
                && Boolean(errors.paymentDetail && errors.paymentDetail.cvv)}
                  helperText={(touched.paymentDetail && touched.paymentDetail.cvv)
                && (errors.paymentDetail && errors.paymentDetail.cvv)}
                />

                <TextField
                  label="Bank Name"
                  id="paymentDetail.bank"
                  className="mt-3"
                  placeholder=""
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                  }}
                  value={values.paymentDetail.bank}
                  onChange={handleChange}
                  error={touched.paymentDetail && touched.paymentDetail.bank
                && Boolean(errors.paymentDetail && errors.paymentDetail.bank)}
                  helperText={touched.paymentDetail && touched.paymentDetail.bank
                && errors.paymentDetail && errors.paymentDetail.bank}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container justify="space-between" direction="column">

                <DatePicker
                  label="Expiration Date"
                  id="paymentDetail.expireDate"
                  value={values.paymentDetail.expireDate}
                  onChange={(value) => setFieldValue('paymentDetail.expireDate', value)}
                  placeholder="dd/mm/yy"
                  inputVariant="outlined"
                  animateYearScrolling
                  format="d/MMM/yyyy"
                  error={touched.expireDate && Boolean(errors.expireDate)}
                  helperText={touched.expireDate && errors.expireDate}
                  disablePast
                />
                <TextField
                  label="Card Holder Name"
                  id="paymentDetail.cardHolderName"
           // className="mt-3 mr-2"
                  placeholder="John Smith"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                  }}
                  value={values.paymentDetail.cardHolderName}
                  onChange={handleChange}
                  error={touched.cardHolderName
                && Boolean(errors.cardHolderName)}
                  helperText={touched.cardHolderName
                && errors.cardHolderName}
                />

                <TextField
                  label="IBAN Number"
                  id="paymentDetail.iban"
                  className="mt-3"
                  placeholder="000 000 000 000 00000"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><></></InputAdornment>,
                  }}
                  value={values.paymentDetail.iban}
                  onChange={handleChange}
                  error={touched.iban && Boolean(errors.iban)}
                  helperText={touched.iban && errors.iban}
                />
              </Grid>
            </Grid>
          </>
          ) }
                */ }
          <Grid item xs={0} md={4} />
          <Grid item xs={11}>
            <FormControlLabel
              control={(
                <Checkbox
                  className="ml-2"
                  value="uncontrolled"
                  inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                  onClick={handleTermsConditionModal}
                  checked={termsAndConditions || false}
                />
)}
              label=" I agree with the Terms & Conditions and the Privacy Policy of PACKR"
            />
          </Grid>
          <Grid item xs={0} md={5} />
          <Grid item xs={7} md={2}>
            <SecondaryButton
              className="mt-5 py-2"
              endIcon={<DoubleArrowOutlinedIcon />}
              type="submit"
           //   onClick={() => redirectTo('orderDetail')}
              disabled={isSubmitting || !termsAndConditions}
              fullWidth
            >
              Submit
            </SecondaryButton>
          </Grid>
          <Grid item xs={1} md={5} />

        </Grid>

      </form>
      <p>&nbsp;</p>
    </MuiPickersUtilsProvider>
  );
}

export default Order;
