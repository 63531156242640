import { createSlice } from '@reduxjs/toolkit';

export const initialState = { tripChats: null };

const slice = createSlice({
  name: 'tripChats',
  initialState,
  reducers: {
    setTripChat(state, action) {
      const { payload } = action;
      return { ...state, ...payload };
    },
    clearTripChat(state) {
      return { ...state, tripChatList: null };
    },
    updateTripChatMsg(state, action) {
      const { payload } = action;
      return {
        ...state,
        data: state.data.map((item) => {
        // Replace the item
          // eslint-disable-next-line no-underscore-dangle
          if (item._id === payload.chatId) {
            return {
              ...item,
              message: payload.message,
              type: 'text',
              created_at: Date.now(),
              unReadCount: '0',
            };
          }

          // Leave every other item unchanged
          return item;
        }),
      };
    },
    updateTripReadCount(state, action) {
      const { payload } = action;
      console.log('🚀 ~ file: orderChats.js ~ line 40 ~ updateReadCount ~ payload', payload);
      return {
        ...state,
        data: state.data.map((item) => {
        // Replace the item
          // eslint-disable-next-line no-underscore-dangle
          if (item._id === payload.chatId) {
            return {
              ...item,
              unReadCount: 0,
            };
          }

          // Leave every other item unchanged
          return item;
        }),
      };
    },
  },
});

export const {
  setTripChat, clearTripChat, updateTripChatMsg, updateTripReadCount,
} = slice.actions;

export default slice.reducer;
