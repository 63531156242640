/* eslint-disable no-unused-vars */
import React from 'react';

import { Grid, Typography, Fab } from '@material-ui/core';
import Slideshow from './slideshow';

const Hero = () => {
  console.log('direction');
  console.log('page');
  return (
    <div className="pt-2 pt-md-5">
      <Grid
        container
        style={{
        //  overflow: 'hidden',
        // padding: '20px',
        // height: '350px',
        //  border: 'solid red',
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="button"
            display="block"
            className="text-uppercase font-weight-bold"
            color="textPrimar"
            gutterBottom
          >
            Reviews
          </Typography>

          <Typography variant="h2" align="left" color="inherit" gutterBottom>
            What people say About us
          </Typography>

        </Grid>
        <Slideshow />
      </Grid>

    </div>

  );
};

export default Hero;
